import Attachment from "common/attachments/Attachment";
import {
  useGetTaskAttachmentsQuery,
  useAddTaskAttachmentsMutation,
  useDeleteAttachmentMutation,
} from "common/attachments/attachmentSlice";
import { store } from "app/store";
import { useLocation } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

function TaskCard({ task, taskAttachments, linkedItem, onStageChange }) {
  let attachmentsInfo = [];
  let consultantId;
  const location = useLocation();
  const { taskId } = (location.state && location.state) || {};
  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;

  const { data, isSuccess, isError, error } = useGetTaskAttachmentsQuery(
    taskId ? taskId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  if (isSuccess) {
    attachmentsInfo = data.attachments;
  }

  const [addTaskAttachemnts] = useAddTaskAttachmentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleAddAttachment = async (formData) => {
    formData.append("task_id", taskId);
    formData.append("user_id", consultantId);
    await addTaskAttachemnts(formData);
  };

  const [deleteAttachemnt] = useDeleteAttachmentMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleDeleteAttachment = async (attachmentId) => {
    await deleteAttachemnt(attachmentId);
  };

  if (isError) {
    return null;
  }

  const handleTaskStageChange = async (stage) => {
    const updatedTask = {
      stage: stage,
    };
    await onStageChange({
      taskId: task.id,
      body: updatedTask,
    });
  };

  return (
    <>
      <h1 className='task-detail-title'>{task.title} </h1>
      <div className='row justify-content-start'>
        <div className='col-2'>
          <div className='btn-group'>
            <select
              className={`form-select-sm border-0 ${
                task.stage === "Incomplete" ? "bg-danger" : "bg-success"
              }`}
              onChange={(e) => handleTaskStageChange(e.target.value)}
              value={task.stage}
            >
              <option className='bg-success' value='Completed'>
                Complete
              </option>
              <option className='bg-danger' value='Incomplete'>
                Incomplete
              </option>
            </select>
          </div>
        </div>
      </div>
      <h2 className='task-content-title'>Linked Item</h2>
      <div className='d-flex align-content-center'>
        <i className='bi bi-briefcase icon-common-task my-0 me-2'></i>
        <p className='icon-title'>{linkedItem}</p>
      </div>
      <div className='row mt-2'>
        <div className='col-4'>
          <h2 className='task-content-title'>Target Date</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <p>{task.end_date}</p>
        </div>
        <div className='col'>
          <p></p>
        </div>
      </div>
      <h2 className='task-content-title'>Description</h2>
      <p>{task.description}</p>
      <hr />
      <Attachment
        attachmentsInfo={attachmentsInfo}
        handleAddAttachment={handleAddAttachment}
      />
    </>
  );
}

export default TaskCard;
