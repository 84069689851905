import { apiSlice } from "app/api";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: () => `/userservice/users`,
    }),
  }),
});

export const { useGetUserListQuery } = userApiSlice;
