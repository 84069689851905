import { skipToken } from "@reduxjs/toolkit/query";
import { Snapshot, parseResults } from "common";
import {
  useGetGoalProjectsQuery,
  useGetVendorProjectsQuery,
  useGetInitiativeProjectsQuery,
} from "features/projects/projectsSlice";

export const PROJECTTYPE = {
  GOAL: "GOAL",
  VENDOR: "VENDOR",
  INITIATIVE: "INITIATIVE",
};

export const ProjectByTypeSnapshot = ({ type, goalOrClientId }) => {
  let functionType;
  if (type === PROJECTTYPE.VENDOR) {
    functionType = useGetVendorProjectsQuery;
  } else if (type === PROJECTTYPE.GOAL) {
    functionType = useGetGoalProjectsQuery;
  } else if (type === PROJECTTYPE.INITIATIVE) {
    functionType = useGetInitiativeProjectsQuery;
  } else {
    // type missing
  }
  let content = {
    labels: ["NO DATA"],
    data: [0.00001],
    quanity: 0,
  };

  const { data, isLoading, isError, error, isSuccess } = functionType(
    goalOrClientId ? goalOrClientId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (isLoading) {
    content = { data: [0.1], labels: ["Loading"] };
  } else if (isSuccess && !isError) {
    let results = parseResults(data.data);
    content.labels = results.map((a) => a.key);
    content.data = results.map((a) => a.value);
    content.quanity = content.data.reduce((a, b) => a + b, 0);
  } else if (isError) {
    console.error(error);
  }

  return (
    <>
      <Snapshot
        title='Projects'
        quanity={content.quanity}
        data={content.data}
        labels={content.labels}
      />
    </>
  );
};
