import { BarGraph } from "features/impacts";
import { useState } from "react";

function InitiativeDropDown({ initiativesInfo }) {
	let allinitIds = [];
	const defaultInitiativeId =
		initiativesInfo.length > 0 ? initiativesInfo[0].id : "0";
	let j = 0;
	for (let i = 0; i < initiativesInfo.length; i++) {
		allinitIds[j] = initiativesInfo[i].id;
		j++;
	}
	const [dropDownId, setDropDownId] = useState(defaultInitiativeId);

	const handleChange = (e) => {
		setDropDownId(e);
	};

	return (
		<div>
			<div className='row mb-4'>
				<div className='col'>
					<div className='card'>
						<div className='card-header'>
							<select
								id='initiative-select'
								className='form-select form-select-sm'
								onChange={(e) => handleChange(e.target.value)}
								defaultValue={defaultInitiativeId}
							>
								<option value='0'>All</option>
								{initiativesInfo.map((initiative) => {
									return (
										<option value={initiative.id}>{initiative.title}</option>
									);
								})}
							</select>
						</div>
						<div className='card-body'>
							{dropDownId === "0" ? (
								allinitIds.map((initId) => (
									<div key={initId}>
										<BarGraph initiativeId={initId} />
									</div>
								))
							) : (
								<BarGraph initiativeId={dropDownId} />
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default InitiativeDropDown;
