import { useGetVendorGoalsQuery } from "features/goals/goalsSlice";
import { useState, useEffect } from "react";
import { useGetGoalsHierarchyQuery } from "features/goals/goalsSlice";
import { Hierarchy } from "common";

function ClientHierarchy({ clientId }) {
  const [expandedGoalId, setExpandedGoalId] = useState([]);
  const [goalInfo, setGoalInfo] = useState([]);
  const [goalHierarchies, setGoalHierarchies] = useState({});
  const [activeGoalId, setActiveGoalId] = useState(null);

  const { data, isSuccess } = useGetVendorGoalsQuery(clientId, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (isSuccess && data.data && Array.isArray(data.data)) {
      setGoalInfo(data.data);
    }
  }, [isSuccess, data]);

  const {
    data: hierarchyDataForSelectedGoal,
    isError,
    refetch,
  } = useGetGoalsHierarchyQuery(activeGoalId, { skip: activeGoalId == null });

  const onGoalClick = async (goalId) => {
    if (expandedGoalId.includes(goalId)) {
      setExpandedGoalId((prev) => prev.filter((id) => id !== goalId));
      setActiveGoalId(null);
    } else {
      setExpandedGoalId((prev) => [...prev, goalId]);
      setActiveGoalId(goalId);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (activeGoalId) {
        await refetch();
      }
    };
    fetchData();
  }, [activeGoalId, refetch]);
  useEffect(() => {
    if (hierarchyDataForSelectedGoal && !isError && activeGoalId) {
      setGoalHierarchies((prev) => ({
        ...prev,
        [activeGoalId]: hierarchyDataForSelectedGoal,
      }));
    }
  }, [hierarchyDataForSelectedGoal, isError, activeGoalId]);

  return (
    <ul>
      {goalInfo.map((goal) => (
        <li key={goal.id} className='root'>
          <div className='d-flex hierarchy-list-item'>
            {expandedGoalId.includes(goal.id) ? (
              <i
                className={`bi bi-chevron-down`}
                onClick={() => onGoalClick(goal.id)}
              />
            ) : (
              <i
                className={`bi bi-chevron-right me-1`}
                onClick={() => onGoalClick(goal.id)}
              />
            )}
            {!expandedGoalId.includes(goal.id) && (
              <i
                className={`bi bi-circle-fill me-2 ${
                  goal.stage === "Not-Started"
                    ? "text-danger"
                    : goal.stage === "In-Progress"
                    ? "text-warning"
                    : "text-success"
                }`}
              ></i>
            )}
            {!expandedGoalId.includes(goal.id) && (
              <p
                className='inventory-item'
                onClick={() => onGoalClick(goal.id)}
              >
                {goal.title}
              </p>
            )}
          </div>
          {expandedGoalId.includes(goal.id) && goalHierarchies[goal.id] && (
            <Hierarchy hierarchy={goalHierarchies[goal.id].data} />
          )}
        </li>
      ))}
    </ul>
  );
}

export default ClientHierarchy;
