import { useGetImpactsHistoryByInitiativeQuery } from "features/impacts/impactsSlice";
import { Chart } from "features/impacts";
import { skipToken } from "@reduxjs/toolkit/query";

function BarGraph({ initiativeId }) {
  let impactHistory = [];
  let currentValue = [];
  let targetValue = [];
  let title;

  const { data, isSuccess } = useGetImpactsHistoryByInitiativeQuery(
    initiativeId ? initiativeId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  let impactIds;
  let impacts = [];

  if (isSuccess) {
    impactHistory = data.data;
    impactIds = new Set(impactHistory.map((c) => c.impact_id));
    impacts = Array.from(impactIds);
  }

  const generateDataSet = (impactId) => {
    let k = 0;
    currentValue = [];
    targetValue = [];
    for (let i = 0; i < impactHistory.length; i++) {
      if (impactHistory[i].impact_id === impactId) {
        currentValue[k] = impactHistory[i].current_value;
        targetValue[k] = impactHistory[i].target_value;
        k++;
        title = impactHistory[i].title;
      }
    }
    return [currentValue, targetValue, title];
  };

  let labels = ["", "", ""];
  return (
    <>
      <div className='container g-4'>
        <div className='row pb-2'>
          {impacts.map((impactId) => {
            generateDataSet(impactId);
            return (
              <Chart
                key={impactId}
                labels={labels}
                data1={currentValue}
                data2={targetValue}
                title={title}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default BarGraph;
