import { BarGraph } from "features/impacts";
import { useLocation } from "react-router-dom";

function InitiativeBarGraph() {
	const location = useLocation();
	const { initiativeId } = (location && location.state) || {};
	return (
		<div className='col'>
			<div className='card'>
				<div className='card-header'>Impacts</div>
				<div className='card-body'>
					<BarGraph initiativeId={initiativeId} />
				</div>
			</div>
		</div>
	);
}

export default InitiativeBarGraph;
