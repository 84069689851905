import { useState, useEffect } from "react";
import getMemoizedSortedData from "common/routines/sortHelper";
import { DeleteDialog } from "common";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

function Table({
  defaultData,
  columns,
  model,
  hideDialog,
  onDelete,
  DELETE_DIALOG_TITLE,
  DELETE_DIALOG_MESSAGE,
}) {
  const [data, setData] = useState(() => [...defaultData]);
  const [sortConfig, setSortConfig] = useState(null);

  useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  const handleSorting = (column) => {
    if (column !== "actions") {
      let { sortedData, config } = getMemoizedSortedData(
        data,
        column,
        sortConfig
      );
      setData(sortedData);
      setSortConfig(config);
    }
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getArrow = (column) => {
    if (column !== "actions") {
      return sortConfig &&
        sortConfig.column === column &&
        sortConfig.direction === "ascending"
        ? "bi bi-arrow-up"
        : "bi bi-arrow-down";
    }
  };

  const handleHidden = (column) => {
    return column !== "actions" && sortConfig && sortConfig.column !== column;
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {model ? (
          <div className='delete-modal'>
            <DeleteDialog
              dialogTitle={DELETE_DIALOG_TITLE}
              descriptionText={DELETE_DIALOG_MESSAGE}
              hide={hideDialog}
              handleDelete={() => onDelete()}
            />
          </div>
        ) : null}
        <table className='table table-hover shadow-lg bg-white custom-table'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={`${
                      index === 0
                        ? "col-5"
                        : index === headerGroup.headers.length - 1 && header.id === "actions"
                        ? "col-1"
                        : "col-2"
                    }`}
                    onClick={() => handleSorting(header.id)}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    <i
                      className={getArrow(header.id)}
                      hidden={handleHidden(header.id)}
                    ></i>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
