import { useState } from "react";
import { ContactsByClient } from "features/contacts";
import { ClientHierarchy } from "features/clients";
import FormHandler from "common/formHandler/FormHandler";
import { useDeleteClientMutation } from "./clientSlice";
import { DeleteDialog } from "common";
import { useNavigate } from "react-router-dom";
import Attachment from "common/attachments/Attachment";
import {
  useAddAccountAttachmentsMutation,
  useGetAccountAttachmentsQuery,
  useDeleteAttachmentMutation,
} from "common/attachments/attachmentSlice";
import { store } from "app/store";

function ClientDetail({ clientInfo, clientId }) {
  let profileImage;
  let attachmentsInfo = [];
  let consultantId;
  const [inventoryId, setInventoryId] = useState(1);
  const navigate = useNavigate();
  const handleChange = (inventoryId) => {
    setInventoryId(inventoryId);
  };

  const [model, setModel] = useState(false);
  const DELETE_DIALOG_TITLE = "Clients";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete this client?`;

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;

  const [deleteClient] = useDeleteClientMutation();

  const deleteDialog = (clientId) => {
    return setModel(true);
  };

  const accountAttachmentsData = useGetAccountAttachmentsQuery(clientId, {
    refetchOnMountOrArgChange: true,
  });
  if (accountAttachmentsData.isSuccess) {
    attachmentsInfo = accountAttachmentsData.data.attachments;
  }
  const [addAccountAttachemnts] = useAddAccountAttachmentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleAddAttachment = async (formData) => {
    formData.append("account_id", clientId);
    formData.append("user_id", consultantId);
    await addAccountAttachemnts(formData);
  };

  const [deleteAccountAttachemnts] = useDeleteAttachmentMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleDeleteAttachment = async (attachmentId) => {
    await deleteAccountAttachemnts(attachmentId);
  };

  const hideDialog = () => {
    setModel(false);
  };

  const deleteHandle = async () => {
    if (clientId) {
      await deleteClient(clientId);
      navigate("/clients");
    }

    setModel(false);
  };

  const renderInventoryContent = () => {
    switch (inventoryId) {
      case 1:
        return <ClientHierarchy clientId={clientId} />;
      case 2:
        return <ContactsByClient />;
      case 3:
        return (
          <Attachment
            attachmentsInfo={attachmentsInfo}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        );
      default:
        return "placeholder text";
    }
  };

  return (
    <div
      className='card p-4 equal-height'
      data-testid='attachment-component'
      id='testing'
    >
      <div className='card-body d-flex flex-column'>
        <div className='d-flex justify-content-end mb-3'>
          <FormHandler
            formInputs={{
              formType: "Client",
              formAction: "Edit",
              itemDetails: {
                id: clientInfo.id,
              },
            }}
          />
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() => deleteDialog(clientInfo.id)}
            ></i>
          </span>
        </div>
        {profileImage !== true && (
          <div className='default-profile rounded-circle d-flex justify-content-center align-items-center '>
            <i className='bi bi-buildings'></i>
            <i className='bi bi-plus profile-pic-hover-icon'></i>
            <div className='overlay'></div>
          </div>
        )}
        {profileImage && (
          <div className='profile-pic-container'>
            <img
              className='ratio ratio-1x1 rounded-circle client-profile-pic'
              alt='client profile'
              src={profileImage}
            />
          </div>
        )}
        <div>
          <h1 className='client-name'>{clientInfo.name}</h1>
        </div>
        <div>
          {clientInfo.address && (
            <address className='client-address my-1'>
              {clientInfo.address}
            </address>
          )}
          {clientInfo.city && clientInfo.state && (
            <p className='client-address my-1'>
              {clientInfo.city}, {clientInfo.state} {clientInfo.zipcode}
            </p>
          )}
          {clientInfo.city && !clientInfo.state && clientInfo.zipcode && (
            <p className='client-address my-1'>
              {clientInfo.city}, {clientInfo.zipcode}
            </p>
          )}
          {clientInfo.city && !clientInfo.state && !clientInfo.zipcode && (
            <p className='client-address my-1'>{clientInfo.city}</p>
          )}
          {!clientInfo.city && clientInfo.state && (
            <p className='client-address my-1'>
              {clientInfo.state} {clientInfo.zipcode}
            </p>
          )}
          {!clientInfo.city && !clientInfo.state && (
            <p className='client-address my-1'>{clientInfo.zipcode}</p>
          )}
          {clientInfo.phoneNumber && (
            <p className='client-address my-1'>ph: {clientInfo.phoneNumber}</p>
          )}
          {clientInfo.website && (
            <p className='client-address'>
              <a href={clientInfo.website}>{clientInfo.website}</a>
            </p>
          )}
        </div>
        <div className='client-icon-group'>
          <button className='btn-basic' onClick={() => handleChange(1)}>
            <i
              className={`bi bi-diagram-3 ${inventoryId === 1 ? "active" : ""}`}
            ></i>
          </button>
          <button className='btn-basic' onClick={() => handleChange(2)}>
            <i
              className={`bi bi-person-square ${
                inventoryId === 2 ? "active" : ""
              }`}
            ></i>
          </button>
          <button
            className='btn-basic'
            data-testid='account-atchmt'
            onClick={() => handleChange(3)}
          >
            <i
              className={`bi bi-paperclip ${inventoryId === 3 ? "active" : ""}`}
            ></i>
          </button>
        </div>
        <div id='inventory-content' className='mt-4'>
          {renderInventoryContent()}
        </div>
      </div>
      {model ? (
        <div className='delete-modal'>
          <DeleteDialog
            dialogTitle={DELETE_DIALOG_TITLE}
            descriptionText={DELETE_DIALOG_MESSAGE}
            hide={() => hideDialog()}
            handleDelete={() => deleteHandle()}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ClientDetail;
