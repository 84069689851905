function DeleteDialog({ dialogTitle, descriptionText, handleDelete, hide }) {
	return (
		<div
			className='modal show fade d-block'
			id='staticBackdrop'
			data-bs-backdrop='static'
			data-bs-keyboard='false'
			aria-labelledby='staticBackdropLabel'
			aria-hidden='true'
		>
			<div className='modal-dialog'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h1 className='modal-title fs-5' id='1'>
							Focus: {dialogTitle}
						</h1>
						<button
							type='button'
							className='btn-close'
							data-bs-dismiss='modal'
							aria-label='Close'
							onClick={hide}
						/>
					</div>
					<div className='modal-body'>{descriptionText}</div>
					<div className='modal-footer'>
						<button
							type='button'
							className='btn btn-secondary'
							data-bs-dismiss='modal'
							onClick={hide}
						>
							Cancel
						</button>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => {
								handleDelete();
								hide();
							}}
							data-bs-dismiss='modal'
						>
							Delete
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DeleteDialog;
