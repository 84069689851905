import { apiSlice } from "app/api";

const impactsSlice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getImpact: build.query({
			query: (impactId) => `/impacts/${impactId}`,
			providesTags: ["Impact"],
		}),
		getConsultantImpacts: build.query({
			query: (ownerConsultantId) =>
				`impacts/impactlist_consultant/${ownerConsultantId}`,
			providesTags: ["Impact"],
		}),
		getCustomerImpacts: build.query({
			query: (ownerCustomerId) =>
				`/impacts/impactlist_customer/${ownerCustomerId}`,
			providesTags: ["Impact"],
		}),
		getAccountImpacts: build.query({
			query: (accountId) => `/impacts/impactlist_account/${accountId}`,
			providesTags: ["Impact"],
		}),
		getVendorImpacts: build.query({
			query: (vendorAccountId) =>
				`/impacts/impactlist_vendor/${vendorAccountId}`,
			providesTags: ["Impact"],
		}),
		getGoalImpacts: build.query({
			query: (goalId) => `/impacts/impactlist_goal/${goalId}`,
			providesTags: ["Impact"],
		}),
		getInitiativeImpacts: build.query({
			query: (Id) => `/impacts/impactlist_initiative/${Id}`,
			providesTags: ["Impact"],
		}),
		getImpactsByStage: build.query({
			query: (goalId) => `/impacts/impactlist_goal/${goalId}`,
			providesTags: ["Impact"],
		}),
		deleteImpact: build.mutation({
			query: (impactId) => ({
				url: `/impacts/${impactId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Impact"],
		}),
		getImpactsHistoryByInitiative: build.query({
			query: (initiativeId) => `/impacts/history/initiative/${initiativeId}`,
			providesTags: ["Impact"],
		}),
		getImpactHistoryByConsultant: build.query({
			query: (consultantId) => `/impacts/history/${consultantId}`,
			providesTags: ["Impact"],
		}),
		getImpactDataPointsByImpactId: build.query({
			query: (impactId) => `/impacts/datapointlist?impact_id=${impactId}`,
			providesTags: ["Impact"],
		}),
		addImpact: build.mutation({
			query: ({ ...body }) => ({
				url: `/impacts/`,
				method: "POST",
				...body,
			}),
			invalidatesTags: ["Impact"],
		}),
		updateImpact: build.mutation({
			query: ({ impactId,...body }) => ({
				url: `/impacts/${impactId}`,
				method: "PATCH",
				...body,
			}),
			invalidatesTags: ["Impact"],
		}),
		overrideExisting: false,
	}),
});

export const {
	useGetImpactDataPointsByImpactIdQuery,
	useGetImpactQuery,
	useGetConsultantImpactsQuery,
	useGetCustomerImpactsQuery,
	useGetAccountImpactsQuery,
	useGetVendorImpactsQuery,
	useGetGoalImpactsQuery,
	useGetInitiativeImpactsQuery,
	useGetImpactsByStageQuery,
	useDeleteImpactMutation,
	useGetImpactsHistoryByInitiativeQuery,
	useGetImpactHistoryByConsultantQuery,
	useAddImpactMutation,
	useUpdateImpactMutation
} = impactsSlice;
