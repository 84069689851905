import { GoalsSnapshot } from "features/goals";
import { ImpactsSnapshot } from "features/impacts";
import { InitiativesSnapshot } from "features/initiatives";
import { Widget } from "common";
import { NewsList } from "features/news/index";
import { Login } from "@microsoft/mgt-react";
import { ScheduleList } from "features/schedule";
import { Task } from "common";
import { ClientList } from "features/clients";
//import FocusScoreChart from "common/charts/FocusScoreChart";
import { FocusScore } from "features/focus-score";
import { store } from "app/store";
import {
  useGetMyTasksQuery,
  useUpdateTaskMutation,
} from "common/task/taskSlice";

function Dashboard() {
  let tasks = [];
  let userId;
  const numIncompleteTask = 5;

  const [updateTask] = useUpdateTaskMutation();

  if (store.getState().auth.userData === null) userId = 0;
  else userId = store.getState().auth.userData.id;

  const { data, isSuccess } = useGetMyTasksQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    tasks = data.tasklist;
  }

  return (
    <>
      <div className='container g-4'>
        <div className='row py-1'>
          <div className='col shadow d-flex p-0 m-2 rounded'>
            <GoalsSnapshot />
          </div>
          <div className='col shadow d-flex p-0 m-2 rounded'>
            <InitiativesSnapshot />
          </div>
          <div className='col shadow d-flex p-0 m-2 rounded'>
            <ImpactsSnapshot />
          </div>
          <div className='col shadow d-flex p-0 m-2 rounded'>
            <FocusScore userid={userId} />
          </div>
        </div>
        <div className='row py-1'>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget h-100'>
              <Widget
                name='News Feed'
                dropDownItems={["Filter", "Newsboard", "Style", "Reset"]}
              />
              <div className='card-body'>
                <NewsList />
              </div>
            </div>
          </div>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget h-100'>
              <Widget
                name='Schedule'
                dropDownItems={[<Login />, "Calendar", "Style", "Reset"]}
              />
              <div className='card-body'>
                <ScheduleList />
              </div>
            </div>
          </div>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget h-100'>
              <Widget
                name='Tasks'
                dropDownItems={["Filter", "Tasks", "Style", "Reset"]}
              />
              <div className='card-body'>
                <Task
                  tasks={tasks}
                  completedSection={false}
                  updateTask={updateTask}
                  numIncompleteTask={numIncompleteTask}
                  breadcrumb='dashboard'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row py-1'>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget'>
              <Widget
                name='Clients'
                dropDownItems={["Filter", "Clientboard", "Style", "Reset"]}
              />
              <div className='card-body'>
                <ClientList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
