import React from "react";
import { Bar } from "react-chartjs-2";
import { useGetImpactHistoryQuery } from "features/focus-score/impactHistorySlice";

const MultiTypeChart = ({ consultantId, impactId }) => {
	let data = {
		targetData: [],
		actualData: [],
		labelData: [],
	};

	// // Helper function to convert 2023-04-23 to 04/23
	// const formatHistoryData = (dateString) => {
	// 	const date = new Date(dateString);
	// 	const month = date.getMonth() + 1;
	// 	const twoDigitYear = date.getFullYear().toString().substring(-2);
	// 	return "${month}/${twoDigitYear}";
	// };

	const parseHistoryData = (values) => {
		if (!values.length) {
			return;
		}

		// Only display the last six entries
		if (values.length > 6) {
			values = values.slice(-6);
		}

		values.forEach((value, idx) => {
			if (value.impact_id === Number(impactId)) {
				data.targetData.push(value?.target_value);
				data.actualData.push(value?.current_value);
				data.labelData.push(idx);
			}
		});
	};

	const { data: impactHistory, isSuccess: isHistorySuccess } =
		useGetImpactHistoryQuery(consultantId, {
			refetchOnMountOrArgChange: true,
		});

	if (isHistorySuccess) {
		parseHistoryData(impactHistory.data);
	}

	const options = {
		layout: {
			padding: {
				top: 10,
			},
		},
		plugins: {
			title: {
				display: false,
				text: "Health Chart- Multi Axis",
			},
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
				},
			},
		},
		responsive: true,
		interaction: {
			mode: "index",
			intersect: false,
		},
		scales: {
			y: {
				ticks: {
					beginAtZero: true,
				},
			},
			x: {
				display: false,
				ticks: {
					beginAtZero: true,
				},
			},
		},
	};

	return (
		<div>
			<Bar
				data={{
					labels: data.labelData,
					datasets: [
						{
							label: "Actual",
							type: "bar",
							data: data.actualData,
							backgroundColor: "rgba(255,99,132,0.4)",
							borderColor: "rgba(255,99,132,1)",
							borderWidth: 1,
						},
						{
							label: "Target",
							type: "line",
							data: data.targetData,

							backgroundColor: "rgba(75,192,192,0.4)",
							borderColor: "rgba(75,192,192,1)",
							borderWidth: 1,
						},
					],
				}}
				options={options}
			/>
		</div>
	);
};

export default MultiTypeChart;
