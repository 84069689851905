/*
Created On: August 02, 2023
Purpose: Handles Attachments Upload, Preview and Delete
Dependencies:
	Components:

	Parameters: 
		1) attachementsInfo: List of attachments to render
		2) handleAddAttachment: Callback method to upload attachements
		3) handleDeleteAttachment: Callback method to delete attachments
*/
import { useRef } from "react";
import { baseUrl } from "app/api/apiSlice";

function Attachment({
  attachmentsInfo,
  handleAddAttachment,
  handleDeleteAttachment,
}) {
  const inputFile = useRef(null);

  const fileUpload = () => {
    inputFile.current.click();
  };
  const handleChange = async (event) => {
    const formData = new FormData();
    [...event.target.files].forEach((file) =>
      formData.append("attachments", file)
    );
    await handleAddAttachment(formData);
    inputFile.current.value = "";
  };

  const getAttachmentName = (attachmentInfo) => {
    let filePath = attachmentInfo.attachment;
    return attachmentInfo.name
      ? attachmentInfo.name
      : filePath.substring(filePath.lastIndexOf("/") + 1);
  };

  const getAttachmentTypeIcon = (fileType) => {
    return fileType
      ? "me-3 bi bi-filetype-" + fileType
      : "me-3 bi bi-file-earmark";
  };

  const downloadAttachment = async (attachmentInfo) => {
    const response = await fetch(baseUrl + attachmentInfo.attachment);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", getAttachmentName(attachmentInfo));
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const previewAttachment = async (attachmentInfo) => {
    const response = await fetch(baseUrl + attachmentInfo.attachment);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const handeDelAttachment = async (attachmentsInfo) => {
    await handleDeleteAttachment(attachmentsInfo.id);
  };

  return (
    <>
      <div className='container' id='attachment-list'>
        <div className='row'>
          <div className='col-11'>
            <h2 className='attachment-title'>Attachments</h2>
          </div>
          <div className='col-1'>
            <i
              className='bi bi-plus-circle add-attachment'
              onClick={fileUpload}
            ></i>
            <input
              type='file'
              multiple
              ref={inputFile}
              onChange={handleChange}
              className='attachment-input-file-upload'
            />
          </div>
        </div>
        <div className='attachmentslist'>
          {attachmentsInfo ? (
            attachmentsInfo.length > 0 ? (
              attachmentsInfo.map((attachmentinfo) => (
                <div key={attachmentinfo.id}>
                  <div className='row' key={attachmentinfo.id}>
                    <div className='col-10 text-truncate'>
                      <i
                        className={getAttachmentTypeIcon(
                          attachmentinfo.attachment_type
                        )}
                      ></i>
                      <small
                        className='attachment-action'
                        onClick={() => previewAttachment(attachmentinfo)}
                      >
                        {getAttachmentName(attachmentinfo)}
                      </small>
                    </div>
                    <div className='col-1 text-end'>
                      <i
                        className='bi bi-download attachment-action'
                        onClick={() => downloadAttachment(attachmentinfo)}
                      ></i>
                    </div>
                    <div className='col-1'>
                      <i
                        className='bi bi-trash attachment-action'
                        onClick={() => handeDelAttachment(attachmentinfo)}
                        data-testid='delete-attachement'
                      ></i>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='row justify-content-md-center'>
                <div className='col-12 text-center'>
                  <small>No Attachments Found</small>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Attachment;
