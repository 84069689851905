import { Snapshot } from "common";
import { useGetConsultantGoalsQuery } from "features/goals/goalsSlice";
import { store } from "app/store";

// Move to a Helper function
function parseResults(array) {
	let r = [],
		o = {};
	array.forEach(function (goal) {
		if (!o[goal.stage]) {
			o[goal.stage] = { key: goal.stage, value: 0 };
			r.push(o[goal.stage]);
		}
		o[goal.stage].value++;
	});

	return r;
}

const GoalsSnapshot = () => {
	let content = {};
	let userId;

	//Giving the userId as 0 if user is not logged in.It is temporary.
	if (store.getState().auth.userData === null) userId = 0;
	else userId = store.getState().auth.userData.id;

	const { data, isLoading, isError } = useGetConsultantGoalsQuery(
		userId,
		{ refetchOnMountOrArgChange: true }
	);

	if (isLoading) {
		content = { data: [0.1], labels: ["Loading"] };
	} else if (isError) {
		content.labels = ["NO DATA"];
		content.data = [0.00001]; // set to value not equal to Zero so a chart can be rendered
		content.quanity = 0;
	} else {
		let results = parseResults(data.data);
		content.labels = results.map((a) => a.key);
		content.data = results.map((a) => a.value);
		content.quanity = content.data.reduce((a, b) => a + b, 0);
	}

	return (
		<>
			<Snapshot
				title='Goals'
				quanity={content.quanity}
				data={content.data}
				labels={content.labels}
			/>
		</>
	);
};

export default GoalsSnapshot;
