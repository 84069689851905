import { apiSlice } from "app/api";

const newsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getNewsFeed: builder.query({
			query: (userId) => `feeds/consultantfeeds/${userId}`,
		}),

		getNewsFeedByAccount: builder.query({
			query: (accountId) => `feeds/accountfeeds/${accountId}`,
		}),

		overrideExisting: false,
	}),
});

export const { useGetNewsFeedQuery, useGetNewsFeedByAccountQuery } =
	newsApiSlice;
