function Dates({ created, started, ended, lastModified }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const yy = date.getFullYear().toString().slice(-4);
    return `${mm}/${dd}/${yy}`;
  };
  return (
    <>
      <p className='inventory-item'>Created: {formatDate(created)}</p>
      <p className='inventory-item'>Started: {formatDate(started)}</p>
      <p className='inventory-item'>Ended: {formatDate(ended)}</p>
      <p className='inventory-item'>
        Last Modified: {formatDate(lastModified)}
      </p>
    </>
  );
}

export default Dates;
