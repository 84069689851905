import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
	name: "auth",
	initialState: { userData: null, token: null, message: null },
	reducers: {
		setCredentials: (state, { payload: { message, token, data } }) => {
			state.userData = data;
			state.token = token;
			state.message = message;
		},
		logOut: (state) => {
			state.userData = null;
			state.token = null;
			state.message = null;
			localStorage.removeItem('persist:counter')
			localStorage.removeItem('auth')		
		}
	},
});

// Action creators will be generated for each reducer function
export const { setCredentials, logOut } = AuthSlice.actions;

export default AuthSlice.reducer;

export const authData = (state) => state.auth;
