import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  useGetVendorClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
} from "features/clients/clientSlice";
import { useGetAccountGoalsQuery } from "features/goals/goalsSlice";
import { useGetGoalInitiativesQuery } from "features/initiatives/initiativesSlice";
import {
  useAddProjectMutation,
  useGetInitiativeProjectsQuery,
  useUpdateProjectMutation,
  useGetProjectQuery,
} from "features/projects/projectsSlice";
import {
  useAddImpactMutation,
  useUpdateImpactMutation,
  useGetImpactQuery,
} from "features/impacts/impactsSlice";
import {
  useAddTaskMutation,
  useUpdateTaskMutation,
} from "features/tasks/tasksSlice";
import { useGetTaskQuery } from "common/task/taskSlice";
import { useSelector } from "react-redux";
import { authData } from "features/auth/authSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetClientDetailsQuery } from "features/clients/clientSlice";
import { useGetUserListQuery } from "features/user/userSlice";
import { ErrorHandler, formValidation } from "common";

const FORM_STAGES = [
  { title: "Not Started", value: "Not-Started" },
  { title: "In Progress", value: "In-Progress" },
  { title: "Completed", value: "Completed" },
];

const FORM_STATUS = [
  { title: "Active", value: "Active" },
  { title: "Inactive", value: "In-Active" },
];

const TASK_STAGES = [
  { title: "Incomplete", value: "Incomplete" },
  { title: "Completed", value: "Completed" },
];

const FormHandler = ({
  formInputs,
  updateGoal,
  addGoal,
  goalData,
  initiativeData,
  addInitiative,
  updateInitiative,
}) => {
  let auth = useSelector(authData).userData;
  const [modalShow, setModalShow] = useState(false);
  const [apiError, setApiError] = useState(null);

  let vendorAccounts = [];
  let goalsByAccount = [];
  let userList = [];
  let initiativeByGoal = [];
  let projectsByInitiative = [];
  const isClientForm = formInputs.formType === "Client";
  const clientDet = useGetClientDetailsQuery(
    auth && auth.organization ? auth.organization : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const initialValues = {
    form_title: "",
    form_description: "",
    form_status: "",
    form_stage: "",
    form_owner_customer: null,
    form_client_account: null,
    form_owner_consultant: "",
    form_start_date: null,
    form_end_date: null,
    form_goal: "",
    form_initiative: "",
    form_project: "",
  };

  const goalInitialValues = {
    form_goal_team: null,
    form_allocated_budget: null,
    form_spend_budget: null,
  };

  const impactInitialValues = {
    form_target_description: "",
    form_range: "",
    form_target_value: null,
    form_current_value: null,
  };

  const clientInitialValues = {
    form_name: "",
    form_account_type: null,
    form_website: null,
    form_phoneNumber: null,
    form_invitation_url: "https://www.a-gassociates.com/",
    form_address: null,
    form_city: null,
    form_state: null,
    form_country: null,
    form_zipcode: null,
    form_email: null,
    form_primary_contact: null,
    form_primary_consultant: null,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [goalFormValues, setGoalFormValues] = useState(goalInitialValues);
  const [impactFormValues, setImpactFormValues] = useState(impactInitialValues);
  const [clientFormValues, setClientFormValues] = useState(clientInitialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleVendorClients = useGetVendorClientsQuery(
    auth && auth.organization ? auth.organization : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (handleVendorClients.isSuccess === true) {
    vendorAccounts = handleVendorClients.data.data;
  }
  const handleUserList = useGetUserListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUserList.isSuccess === true) {
    userList = handleUserList.data.data;
  }

  const getGoalsByAccount = useGetAccountGoalsQuery(
    auth && auth.organization ? auth.organization : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (getGoalsByAccount.isSuccess === true) {
    goalsByAccount = getGoalsByAccount.data.data;
  }

  const handleInitiatives = useGetGoalInitiativesQuery(
    formValues.form_goal ? formValues.form_goal : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (handleInitiatives.isSuccess === true) {
    initiativeByGoal = handleInitiatives.data.data;
  }

  const handleProjects = useGetInitiativeProjectsQuery(
    formValues.form_initiative ? formValues.form_initiative : skipToken,
    {
      skipToken,
    }
  );

  if (handleProjects.isSuccess === true) {
    projectsByInitiative = handleProjects.data.data;
  }

  const onHide = () => {
    setFormErrors({});
    setFormValues(initialValues);
    setGoalFormValues(goalInitialValues);
    setImpactFormValues(impactInitialValues);
    setClientFormValues(clientInitialValues);
    setModalShow(false);
  };
  const handleAddClick = () => {
    setModalShow(!modalShow);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleGoalFormChanges = (e) => {
    const { id, value } = e.target;
    setGoalFormValues({ ...goalFormValues, [id]: value });
  };

  const handleImpactFormChanges = (e) => {
    const { id, value } = e.target;
    setImpactFormValues({ ...impactFormValues, [id]: value });
  };

  const handleClientFormChanges = (e) => {
    const { id, value } = e.target;
    setClientFormValues({ ...clientFormValues, [id]: value });
  };

  const [addProject] = useAddProjectMutation({
    refetchOnMountOrArgChange: true,
  });

  const [addTask] = useAddTaskMutation({
    refetchOnMountOrArgChange: true,
  });

  const [addImpact] = useAddImpactMutation({
    refetchOnMountOrArgChange: true,
  });

  const [addClient] = useAddClientMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateProject] = useUpdateProjectMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateTask] = useUpdateTaskMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateClient] = useUpdateClientMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateImpact] = useUpdateImpactMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    let body;
    let errors;
    let fin_body = {};
    let form_Values = {
      ...formValues,
      form_vendor_account: parseInt(formValues.form_client_account),
      form_owner_consultant: parseInt(formValues.form_owner_consultant),
      form_start_date: formValues.form_start_date
        ? formValues.form_start_date
        : null,
      form_end_date: formValues.form_end_date ? formValues.form_end_date : null,
    };
    let userDet = {
      form_account: auth.organization,
      form_created_by: auth.id,
    };
    if (formInputs.formType === "Goal") {
      let goal_FormValues = {
        ...goalFormValues,
        form_allocated_budget: goalFormValues.form_allocated_budget
          ? parseFloat(goalFormValues.form_allocated_budget).toFixed(2)
          : goalFormValues.form_allocated_budget,
        form_spend_budget: goalFormValues.form_spend_budget
          ? parseFloat(goalFormValues.form_spend_budget).toFixed(2)
          : goalFormValues.form_spend_budget,
      };
      body = {
        ...userDet,
        ...form_Values,
        ...goal_FormValues,
      };
    }
    if (formInputs.formType === "Initiative") {
      body = {
        ...userDet,
        ...form_Values,
      };
      body["form_spend_budget"] = goalFormValues.form_spend_budget;
    }
    if (formInputs.formType === "Projects") {
      body = {
        ...userDet,
        ...form_Values,
      };
    }
    if (formInputs.formType === "Task") {
      body = {
        ...userDet,
        ...form_Values,
      };
    }

    if (formInputs.formType === "Impact") {
      let impact_formValues = {
        ...impactFormValues,
        form_range: parseInt(impactFormValues.form_range),
        form_target_value: impactFormValues.form_target_value
          ? parseFloat(impactFormValues.form_target_value).toFixed(2)
          : impactFormValues.form_target_value,
        form_current_value: impactFormValues.form_current_value
          ? parseFloat(impactFormValues.form_current_value).toFixed(2)
          : impactFormValues.form_current_value,
      };
      body = {
        ...userDet,
        ...form_Values,
        ...impact_formValues,
      };
    }

    if (formInputs.formType === "Client") {
      body = {
        ...clientFormValues,
        form_vendor_account: auth.organization,
      };
    }

    Object.entries(body).forEach((i) => {
      const [key, value] = i;
      fin_body[key.split("form_")[1]] = value;
    });
    if (formInputs.formType === "Client") {
      errors = formValidation(
        formValues,
        goalFormValues,
        impactFormValues,
        clientFormValues,
        formInputs.formType
      );
    } else {
      errors = formValidation(
        formValues,
        goalFormValues,
        impactFormValues,
        clientFormValues,
        formInputs.formType
      );
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      let result;
      if (formInputs.formType === "Goal") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addGoal(fin_body);
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateGoal({
              goalId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occuured");
          }
        }
      }

      if (formInputs.formType === "Initiative") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addInitiative({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateInitiative({
              initiativeId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Projects") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addProject({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateProject({
              projectId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Task") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addTask({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateTask({
              taskId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Impact") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addImpact({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateImpact({
              impactId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Client") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addClient({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateClient({
              clientId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (result.data.status === "success") {
        onHide();
      }
    }
  };

  const getProjectData = useGetProjectQuery(
    formInputs.formType === "Projects" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken
  );

  const getTaskData = useGetTaskQuery(
    formInputs.formType === "Task" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken
  );

  const getImpactData = useGetImpactQuery(
    formInputs.formType === "Impact" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken
  );

  const getClientData = useGetClientDetailsQuery(
    formInputs.formType === "Client" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken
  );

  const handleEditClick = async () => {
    setModalShow(modalShow ? false : true);
    if (
      goalData ||
      initiativeData ||
      getProjectData.isSuccess ||
      getTaskData.isSuccess ||
      getImpactData.isSuccess ||
      getClientData.isSuccess
    ) {
      let item_data;
      if (formInputs.formType !== "Client") {
        if (formInputs.formType === "Goal") {
          item_data = goalData;
          let goal_value = {
            form_goal_team: item_data.goal_team,
            form_allocated_budget: item_data.allocated_budget,
            form_spend_budget: item_data.spend_budget,
          };
          setGoalFormValues(goal_value);
        }
        if (formInputs.formType === "Initiative") {
          item_data = initiativeData;
        }
        if (formInputs.formType === "Projects") {
          item_data = getProjectData.data.data;
        }
        if (formInputs.formType === "Impact") {
          item_data = getImpactData.data.data;
          let impact_values = {
            form_target_description: item_data.target_description,
            form_current_value: item_data.current_value,
            form_target_value: item_data.target_value,
            form_range: item_data.range,
          };
          setImpactFormValues(impact_values);
        }
        if (formInputs.formType === "Task") {
          item_data = getTaskData.data.data;
        }
        let values = {
          form_title: item_data.title,
          form_description: item_data.description,
          form_status: item_data.status,
          form_stage: item_data.stage,
          form_owner_customer: item_data.owner_customer,
          form_client_account: item_data.vendor_account,
          form_owner_consultant: item_data.owner_consultant,
          form_start_date: item_data.start_date,
          form_end_date: item_data.end_date,
          form_goal: item_data.goal,
          form_initiative: item_data.initiative,
          form_project: item_data.project,
        };
        setFormValues(values);
      } else {
        item_data = getClientData.data.data;
        let client_value = {
          form_name: item_data.name,
          form_account_type: item_data.account_type,
          form_website: item_data.website,
          form_phoneNumber: item_data.phoneNumber,
          form_invitation_url: item_data.invitation_url,
          form_address: item_data.address,
          form_city: item_data.city,
          form_state: item_data.state,
          form_country: item_data.country,
          form_zipcode: item_data.zipcode,
          form_email: item_data.email,
          form_primary_contact: item_data.primary_contact,
          form_primary_consultant: item_data.primary_consultant,
        };
        setClientFormValues(client_value);
      }
    }
  };

  const onClose = () => {
    setApiError(null);
  };

  useEffect(() => {}, [formErrors]);

  const formContent = () => {
    return (
      <>
        <form className='row mb-2' id='commonForm'>
          {!isClientForm && (
            <>
              <div className='form-group col-12 mb-3'>
                <input
                  type='text'
                  id='form_title'
                  placeholder='Title'
                  value={formValues.form_title}
                  onChange={handleChange}
                  className={`form-control ${
                    formErrors.form_title ? "is-invalid" : ""
                  }`}
                />
                {formErrors?.form_title && (
                  <div className='invalid-feedback'>
                    {formErrors.form_title}
                  </div>
                )}
              </div>
              <div className='form-group col-12 mb-3'>
                <textarea
                  className={`form-control ${
                    formErrors.form_description ? "is-invalid" : ""
                  }`}
                  id='form_description'
                  rows='3'
                  placeholder='Description'
                  value={formValues.form_description}
                  onChange={handleChange}
                ></textarea>
                {formErrors?.form_description && (
                  <div className='invalid-feedback'>
                    {formErrors.form_title}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <Form.Group controlId='duedate'></Form.Group>
                <input
                  type='text'
                  className={`form-control ${
                    formErrors.form_start_date ? "is-invalid" : ""
                  }`}
                  placeholder='Select Start Date'
                  id='form_start_date'
                  value={formValues.form_start_date}
                  onChange={handleChange}
                  onFocus={() => {
                    let start_date = document.getElementById("form_start_date");
                    start_date.type = "date";
                  }}
                  onBlur={() => {
                    let start_date = document.getElementById("form_start_date");
                    start_date.type = "text";
                  }}
                />
              </div>
              <div className='col-6 mb-3'>
                <input
                  type='text'
                  className={`form-control ${
                    formErrors.form_end_date ? "is-invalid" : ""
                  }`}
                  id='form_end_date'
                  value={formValues.form_end_date}
                  onChange={handleChange}
                  min={
                    formValues.form_start_date
                      ? formValues.form_start_date
                      : null
                  }
                  placeholder='Select End Date'
                  onFocus={() => {
                    let start_date = document.getElementById("form_end_date");
                    start_date.type = "date";
                  }}
                  onBlur={() => {
                    let start_date = document.getElementById("form_end_date");
                    start_date.type = "text";
                  }}
                />

                {formErrors?.form_end_date && (
                  <div className='invalid-feedback'>
                    {formErrors.form_end_date}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  id='form_stage'
                  value={formValues.form_stage}
                  onChange={handleChange}
                  className={`form-select ${
                    formErrors.form_stage ? "is-invalid" : ""
                  }`}
                  required
                >
                  <option selected value=''>
                    {"Select " + formInputs.formType + " Stage"}
                  </option>
                  {formInputs.formType !== "Task"
                    ? FORM_STAGES.map((goal) => (
                        <option value={goal.value}>{goal.title}</option>
                      ))
                    : null}
                  {formInputs.formType === "Task"
                    ? TASK_STAGES.map((goal) => (
                        <option value={goal.value}>{goal.title}</option>
                      ))
                    : null}
                </select>
                {formErrors?.form_stage && (
                  <div className='invalid-feedback'>
                    {formErrors.form_stage}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  className={`form-select ${
                    formErrors.form_status ? "is-invalid" : ""
                  }`}
                  id='form_status'
                  value={formValues.form_status}
                  onChange={handleChange}
                  required
                >
                  <option selected value=''>
                    {"Select " + formInputs.formType + " Status"}
                  </option>
                  {FORM_STATUS.map((goal) => (
                    <option value={goal.value}>{goal.title}</option>
                  ))}
                </select>
                {formErrors?.form_status && (
                  <div className='invalid-feedback'>
                    {formErrors.form_status}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  className={`form-select ${
                    formErrors.form_client_account ? "is-invalid" : ""
                  }`}
                  id='form_client_account'
                  value={formValues.form_client_account}
                  onChange={handleChange}
                >
                  <option selected value=''>
                    Select Client Account
                  </option>
                  {vendorAccounts.length > 0 &&
                    vendorAccounts.map((vac) => (
                      <option value={vac.id}>{vac.name}</option>
                    ))}
                </select>
                {formErrors?.form_client_account && (
                  <div className='invalid-feedback'>
                    {formErrors.form_client_account}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  className={`form-select ${
                    formErrors.form_owner_consultant ? "is-invalid" : ""
                  }`}
                  id='form_owner_consultant'
                  value={formValues.form_owner_consultant}
                  onChange={handleChange}
                  required
                >
                  <option selected value=''>
                    Select Owner Consultant
                  </option>
                  {userList.length > 0 &&
                    userList.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name}
                        {user.id === auth.id ? " (Current)" : ""}
                      </option>
                    ))}
                </select>
                {formErrors?.form_owner_consultant && (
                  <div className='invalid-feedback'>
                    {formErrors.form_owner_consultant}
                  </div>
                )}
              </div>
              {goalFormContents}
              <div
                className='col-6 mb-3'
                hidden={
                  !["Initiative", "Projects", "Impact", "Task"].includes(
                    formInputs.formType
                  )
                }
              >
                <select
                  className={`form-select ${
                    formErrors.form_goal ? "is-invalid" : ""
                  }`}
                  id='form_goal'
                  value={formValues.form_goal}
                  onChange={handleChange}
                >
                  <option selected value=''>
                    Select Goal
                  </option>
                  {goalsByAccount &&
                    goalsByAccount.map((goal) => (
                      <option className='' value={goal.id}>
                        <small>{goal.title}</small>
                      </option>
                    ))}
                </select>
                {formErrors?.form_goal && (
                  <div className='invalid-feedback'>{formErrors.form_goal}</div>
                )}
              </div>
              <div
                className='col-6 mb-3'
                hidden={
                  !["Projects", "Impact", "Task"].includes(formInputs.formType)
                }
              >
                <select
                  className='form-select'
                  id='form_initiative'
                  disabled={!formValues.form_goal}
                  value={formValues.form_initiative}
                  onChange={handleChange}
                >
                  <option value='' selected>
                    Select Initiative
                  </option>
                  {initiativeByGoal.map((init) => (
                    <option value={init.id}>{init.title}</option>
                  ))}
                </select>
              </div>
              {impactFormContents}
              <div
                className='col-6 mb-3'
                hidden={!["Task"].includes(formInputs.formType)}
              >
                <select
                  className='form-select'
                  disabled={
                    !formValues.form_goal || !formValues.form_initiative
                  }
                  id='form_project'
                  value={formValues.form_project}
                  onChange={handleChange}
                >
                  <option value='' selected>
                    Select Projects
                  </option>
                  {projectsByInitiative.map((project) => (
                    <option value={project.id}>{project.title}</option>
                  ))}
                </select>
              </div>
            </>
          )}
          {isClientForm && clientFormContents}
          <div className='col-12 mt-3 d-flex justify-content-end'>
            <div className='col-6 d-inline-flex ps-3 pe-1'>
              <Button
                className='w-50 me-2'
                variant={"outline-secondary"}
                onClick={onHide}
              >
                Cancel
              </Button>
              <Button
                className='w-50'
                variant={"primary"}
                onClick={(event) => handleSubmit(event)}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const goalFormContents = (
    <>
      <div
        className='col-6 mb-3'
        hidden={!["Goal"].includes(formInputs.formType)}
      >
        <div className='input-group'>
          <div className='input-group-prepend'>
            <div className='input-group-text'>$</div>
          </div>
          <input
            type='number'
            className={`form-control ${
              formErrors.form_allocated_budget ? "is-invalid" : ""
            }`}
            id='form_allocated_budget'
            placeholder='Allocated Budget'
            value={goalFormValues.form_allocated_budget}
            onChange={handleGoalFormChanges}
          />

          {formErrors?.form_allocated_budget && (
            <div className='invalid-feedback'>
              {formErrors.form_allocated_budget}
            </div>
          )}
        </div>
      </div>
      <div
        className='col-6 mb-3'
        hidden={!["Goal", "Initiative"].includes(formInputs.formType)}
      >
        <div className='input-group'>
          <div className='input-group-prepend'>
            <div className='input-group-text'>$</div>
          </div>
          <input
            type='number'
            className={`form-control ${
              formErrors.form_spend_budget ? "is-invalid" : ""
            }`}
            id='form_spend_budget'
            placeholder='Spend Budget'
            value={goalFormValues.form_spend_budget}
            onChange={handleGoalFormChanges}
          />
          {/* <div className='invalid-feedback'>{formErrors.spend_budget}</div> */}
          {formErrors?.form_spend_budget && (
            <div className='invalid-feedback'>
              {formErrors.form_spend_budget}
            </div>
          )}
        </div>
      </div>
    </>
  );

  const impactFormContents = (
    <>
      <div
        className='form-group col-6 mb-3'
        hidden={!["Impact"].includes(formInputs.formType)}
      >
        <textarea
          className={`form-control ${
            formErrors.form_target_description ? "is-invalid" : ""
          }`}
          rows='1'
          placeholder='Target Description'
          id='form_target_description'
          value={impactFormValues.form_target_description}
          onChange={handleImpactFormChanges}
        ></textarea>
        {formErrors?.form_target_description && (
          <div className='invalid-feedback'>
            {formErrors.form_target_description}
          </div>
        )}
      </div>
      <div
        className='col-6 mb-3'
        hidden={!["Impact"].includes(formInputs.formType)}
      >
        <select
          className='form-select'
          id='form_range'
          onChange={handleImpactFormChanges}
          value={impactFormValues.form_range}
        >
          <option selected>Select Range</option>
          <option value='1'>One</option>
          <option value='2'>Two</option>
          <option value='3'>Three</option>
          <option value='4'>Four</option>
          <option value='5'>Five</option>
        </select>
      </div>
      <div
        className='col-6 mb-3'
        hidden={!["Impact"].includes(formInputs.formType)}
      >
        <div className='input-group'>
          <input
            type='number'
            className={`form-control ${
              formErrors.form_current_value ? "is-invalid" : ""
            }`}
            id='form_current_value'
            placeholder='Current Value'
            onChange={handleImpactFormChanges}
            value={impactFormValues.form_current_value}
          />
          {formErrors?.form_current_value && (
            <div className='invalid-feedback'>
              {formErrors.form_current_value}
            </div>
          )}
        </div>
      </div>
      <div
        className='col-6 mb-3'
        hidden={!["Impact"].includes(formInputs.formType)}
      >
        <div className='input-group'>
          <input
            type='number'
            className={`form-control ${
              formErrors.form_target_value ? "is-invalid" : ""
            }`}
            id='form_target_value'
            placeholder='Target Value'
            onChange={handleImpactFormChanges}
            value={impactFormValues.form_target_value}
          />
          {formErrors?.form_target_value && (
            <div className='invalid-feedback'>
              {formErrors.form_target_value}
            </div>
          )}
        </div>
      </div>
    </>
  );

  const clientFormContents = (
    <>
      <div className='col-6 mb-5'>
        <select
          className='form-select'
          id='form_account_type'
          value={clientFormValues.form_account_type}
          onChange={handleClientFormChanges}
        >
          <option selected value=''>
            Select Account Type
          </option>
          <option value='Consultant'>Consultant</option>
          <option value='Federal Government'>Federal Government</option>
          <option value='State/Local Government'>State/Local Government</option>
          <option value='Nonprofit'>Nonprofit</option>
          <option value='For-profit'>For-profit</option>
          <option value='Others'>Others</option>
        </select>
      </div>
      <div className='col-6 mb-5'>
        <select
          className={`form-select ${
            formErrors.form_primary_consultant ? "is-invalid" : ""
          }`}
          id='form_primary_consultant'
          value={clientFormValues.form_primary_consultant}
          onChange={handleClientFormChanges}
          required
        >
          <option selected value=''>
            Primary Consultant
          </option>
          {userList.length > 0 &&
            userList.map((user) => (
              <option key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
                {user.id === auth.id ? " (Current)" : ""}
              </option>
            ))}
        </select>
        {formErrors?.form_primary_consultant && (
          <div className='invalid-feedback'>
            {formErrors.form_primary_consultant}
          </div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <select
          className='form-select'
          id='form_client_primary_contact'
          value={formValues.form_primary_contact}
          onChange={handleClientFormChanges}
        >
          <option selected value=''>
            Primary Contact
          </option>
          <option value='23'>Chris</option>
        </select>
      </div>
      <div className='col-6 mb-3'></div>
      <div className='col-6 mb-5'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_email ? "is-invalid" : ""
          }`}
          id='form_email'
          placeholder='Email'
          value={clientFormValues.form_email}
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_email && (
          <div className='invalid-feedback'>{formErrors.form_email}</div>
        )}
      </div>
      <div className='col-6 mb-5'>
        <input
          type='tel'
          className={`form-control ${
            formErrors.form_phoneNumber ? "is-invalid" : ""
          }`}
          id='form_phoneNumber'
          placeholder='Phone Number'
          value={clientFormValues.form_phoneNumber}
          onChange={handleClientFormChanges}
          style={{
            ...(formErrors.phoneNumber
              ? {
                  borderColor: "#c44b6c",
                  backgroundImage: "none",
                }
              : {}),
          }}
        />
        {formErrors?.form_phoneNumber && (
          <div className='invalid-feedback'>{formErrors.form_phoneNumber}</div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${formErrors.form_name ? "is-invalid" : ""}`}
          id='form_name'
          placeholder='Company/ Org Name'
          value={clientFormValues.form_name}
          onChange={handleClientFormChanges}
          required
        />
        {formErrors?.form_name && (
          <div className='invalid-feedback'>{formErrors.form_name}</div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_website ? "is-invalid" : ""
          }`}
          id='form_website'
          placeholder='Website'
          value={clientFormValues.form_website}
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_website && (
          <div className='invalid-feedback'>{formErrors.form_website}</div>
        )}
      </div>
      <div className='col-12 mb-3'>
        <input
          type='text'
          className='form-control'
          id='form_address'
          placeholder='Address'
          value={clientFormValues.form_address}
          onChange={handleClientFormChanges}
        />
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${formErrors.form_city ? "is-invalid" : ""}`}
          id='form_city'
          placeholder='City'
          value={clientFormValues.form_city}
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_city && (
          <div className='invalid-feedback'>{formErrors.form_city}</div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_state ? "is-invalid" : ""
          }`}
          id='form_state'
          placeholder='State'
          value={clientFormValues.form_state}
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_state && (
          <div className='invalid-feedback'>{formErrors.form_state}</div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_country ? "is-invalid" : ""
          }`}
          id='form_country'
          placeholder='Country'
          value={clientFormValues.form_country}
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_country && (
          <div className='invalid-feedback'>{formErrors.form_country}</div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_zipcode ? "is-invalid" : ""
          }`}
          id='form_zipcode'
          placeholder='Zipcode'
          value={clientFormValues.form_zipcode}
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_zipcode && (
          <div className='invalid-feedback'>{formErrors.form_zipcode}</div>
        )}
      </div>
    </>
  );

  useEffect(() => {});

  return (
    <>
      {formInputs.formAction === "Add" ? (
        <Button onClick={handleAddClick} className='btn-sm my-1'>
          <i className='bi bi-plus'></i>
        </Button>
      ) : null}
      {formInputs.formAction === "Edit" ? (
        <i
          className='bi bi-pencil-square'
          data-bs-toggle='tooltip'
          data-bs-placment='top'
          onClick={handleEditClick}
        ></i>
      ) : null}
      {apiError ? <ErrorHandler error={apiError} onClose={onClose} /> : ""}
      <Modal
        show={modalShow}
        onHide={onHide}
        size='lg'
        backdrop='static'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {clientDet && clientDet.data && clientDet.data.data.name
              ? clientDet.data.data.name + " - "
              : null}{" "}
            {formInputs.formAction + " " + formInputs.formType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{formContent(formInputs)}</Modal.Body>
      </Modal>
    </>
  );
};

export default FormHandler;
