import { apiSlice } from "app/api";

const commentsSlice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getComments: build.query({
			query: ({type,parentId}) => `/comments/${type}/${parentId}`,
			providesTags: ["Comment"],
		}),
		addComments: build.mutation({
			query: (formData) => ({
				url: `/comments/`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Comment"],
		}),
		overrideExisting: false,
	}),
});
export const { useGetCommentsQuery, useAddCommentsMutation } =
	commentsSlice;
