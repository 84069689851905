import { Table } from "common";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  useGetConsultantTasksQuery,
  useGetAccountTasksQuery,
} from "common/task/taskSlice";
import { store } from "app/store";
import { createColumnHelper } from "@tanstack/react-table";
import { useGetUserListQuery } from "features/user/userSlice";

function TaskTable() {
  let userList = [];
  let taskListByConsultant = [];
  let taskListByAccount = [];
  let consultantId;
  let accountId;
  const [model, setModel] = useState(false);
  const [viewAll, setviewAll] = useState("MINE");

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;
  if (store.getState().auth.userData === null) accountId = 0;
  else accountId = store.getState().auth.userData.organization;

  const { data: taskByAccountInfo, isSuccess: isTaskByAccountSuccess } =
    useGetAccountTasksQuery(accountId, {
      refetchOnMountOrArgChange: true,
    });
  if (isTaskByAccountSuccess) {
    taskListByAccount = taskByAccountInfo.data;
  }

  const { data: taskByConsultantInfo, isSuccess: isTaskByConsultantSuccess } =
    useGetConsultantTasksQuery(consultantId, {
      refetchOnMountOrArgChange: true,
    });
  if (isTaskByConsultantSuccess) {
    taskListByConsultant = taskByConsultantInfo.data;
  }

  const handleUserList = useGetUserListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUserList.isSuccess === true) {
    userList = handleUserList.data.data;
  }

  const hideDialog = () => {
    setModel(false);
  };

  const formatDate = (inputDate) => {
    if (inputDate) {
      let [year, month, day] = inputDate.split("-").map(Number);
      let formatedDate = new Date(year, month - 1, day);
      return formatedDate.toDateString();
    }
  };

  const formatOwnerConsultant = (id) => {
    if (id) {
      const consultant = userList.find((user) => user.id === id);
      return consultant
        ? `${consultant.first_name} ${consultant.last_name}`
        : "";
    }
    return "";
  };

  let defaultData = [];

  if (viewAll === "ALL") {
    defaultData = taskListByAccount || [];
  }
  if (viewAll === "MINE") {
    defaultData = taskListByConsultant || [];
  }
  const columnHelper = createColumnHelper();

  const columnsAll = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/tasks/${info.row.original.id}`}
          state={{ taskId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Incomplete" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),

    columnHelper.accessor((row) => row.owner_consultant, {
      id: "owner_consultant",
      cell: (info) => formatOwnerConsultant(info.getValue()),
      header: () => <span>Owner Consultant</span>,
      show: viewAll === "ALL",
    }),
  ];

  const columnsMine = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/tasks/${info.row.original.id}`}
          state={{ taskId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Incomplete" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),
  ];

  return (
    <div>
      <div className='row'>
        <div className='col-1'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View all'
              checked={viewAll === "ALL"}
              onChange={() => {
                setviewAll("ALL");
              }}
            />
          </Form>
        </div>
        <div className='col-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View mine'
              checked={viewAll === "MINE"}
              onChange={() => {
                setviewAll("MINE");
              }}
            />
          </Form>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {viewAll === "ALL" ? (
            <Table
              defaultData={defaultData}
              columns={columnsAll}
              model={model}
              hideDialog={hideDialog}
            />
          ) : (
            <Table
              defaultData={defaultData}
              columns={columnsMine}
              model={model}
              hideDialog={hideDialog}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskTable;
