export function parseResults(array) {
  let r = [],
    o = {};
  array.forEach(function (goal) {
    if (!o[goal.stage]) {
      o[goal.stage] = { key: goal.stage, value: 0 };
      r.push(o[goal.stage]);
    }
    o[goal.stage].value++;
  });
  return r;
}
