function TaskCardEdit({
	task,
	taskAttachments,
	linkedItem,
	onStageChange,
	editForm,
}) {
	const handleSave = () => {
		const newTaskDetails = {
			taskName: document.getElementById("task-title").value,
			taskEndDate: document.getElementById("task-date").value,
			taskDescription: document.getElementById("task-description").value,
		};
		const updatedTask = {
			title: newTaskDetails.taskName,
			end_date: newTaskDetails.taskEndDate,
			description: newTaskDetails.taskDescription,
		};
		onStageChange({
			taskId: task.id,
			body: updatedTask,
		});
		editForm(false);
	};
	const handleCancel = () => {
		editForm(false);
	};
	const handleTaskStageChange = async (stage) => {
		const updatedTask = {
			stage: stage,
		};
		await onStageChange({
			taskId: task.id,
			body: updatedTask,
		});
	};

	return (
		<>
			<input
				id='task-title'
				className='form-control my-2'
				defaultValue={task.title}
			/>
			<div className='row justify-content-start'>
				<div className='col-2'>
					<div className='btn-group'>
						<select
							className={`form-select-sm border-0 ${
								task.stage === "Incomplete" ? "bg-danger" : "bg-success"
							}`}
							onChange={(e) => handleTaskStageChange(e.target.value)}
							value={task.stage}
						>
							<option className='bg-success' value='Completed'>
								Complete
							</option>
							<option className='bg-danger' value='Incomplete'>
								Incomplete
							</option>
						</select>
					</div>
				</div>
			</div>
			<h1 className='task-content-title'>Linked Item</h1>

			<div className='d-flex align-content-center'>
				<i className='bi bi-briefcase icon-common-task my-0 me-2'></i>
				<p className='icon-title'>{linkedItem}</p>
			</div>
			<div className='row mt-2'>
				<div className='col-4'>
					<h2 className='task-content-title'>Target Date</h2>
				</div>
			</div>
			<div className='row'>
				<div className='col-4'>
					<input
						id='task-date'
						type='date'
						className='form-control'
						defaultValue={task.end_date}
					/>
				</div>
				<div className='col'>
					<p></p>
				</div>
			</div>
			<h2 className='task-content-title'>Description</h2>
			<textarea
				id='task-description'
				className='form-control'
				rows={4}
				defaultValue={task.description}
			/>
			<div>
				<button
					type='button'
					className='btn btn-primary task-edit-save btn-sm me-2'
					onClick={handleSave}
				>
					Save
				</button>
				<button
					type='button'
					className='btn btn-outline-secondary task-edit-cancel btn-sm'
					onClick={handleCancel}
				>
					Cancel
				</button>
			</div>
			<hr />
			<div className='d-flex justify-content-between mt-2'>
				<div>
					<h2 className='attachment-title'>Attachments</h2>
				</div>

				<div>
					<i className='bi bi-plus icon-common-task'></i>
				</div>
			</div>
			<div className='d-flex justify-content-between'>
				<div className='d-flex flex-grow-1'>
					<div className='d-flex align-content-center'>
						<i className='bi bi-card-image icon-view-attachment me-2'></i>
						<p className='icon-title'>{taskAttachments.attachment}</p>
					</div>
				</div>
				<div className='d-flex flex-shrink-0'>
					<div className='d-flex align-content-center'>
						<p className='my-0 me-3 attachment-details'>66KB</p>
						<p className='my-0 me-5 attachment-details'>
							{taskAttachments.created_on_date}{" "}
						</p>
						<i className='bi bi-eye icon-common-task me-2'></i>
						<i className='bi bi-cloud-download icon-common-task me-2'></i>
					</div>
				</div>
			</div>
		</>
	);
}

export default TaskCardEdit;
