import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseUrl =
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_API_ENV === "production"
    ? "https://focus-django-prod-ec168923d0d9.herokuapp.com"
    : "https://focus-django-stage-a3f09bb2c393.herokuapp.com";
export const apiBaseUrl = baseUrl + "/api/v1/";

const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Token ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery: baseQuery,
  tagTypes: [
    "Goal",
    "Impact",
    "Initiative",
    "Project",
    "Task",
    "Comment",
    "Attachment",
    "Account",
  ],
  endpoints: () => ({}),
});
