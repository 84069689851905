import "app/app.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "features/ui";
import {
	Calendar,
	Dashboard,
	Projects,
	Project,
	Tasks,
	Task,
	FileManager,
	Signup,
	Login,
	UserProfile,
	NotFound,
	Impacts,
	Initiatives,
	Initiative,
	Goals,
	Goal,
	Impact,
	Client,
	Clients,
} from "pages";
import RouteHandler from "./RouteHandler";

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route path='/signup' element={<Signup />} />
					<Route path='/login' element={<Login />} />
					<Route element={<RouteHandler />}>
						<Route path='/' element={<Layout />}>
							<Route index element={<Dashboard />} />
							<Route path='projects' element={<Projects />} />
							<Route path='projects/:id' element={<Project />} />
							<Route path='clients' element={<Clients />} />
							<Route path='clients/:id' element={<Client />} />
							<Route path='tasks' element={<Tasks />} />
							<Route path='tasks/:id' element={<Task />} />
							<Route path='calendar' element={<Calendar />} />
							<Route path='file-manager' element={<FileManager />} />
							<Route path='profile' element={<UserProfile />} />
							<Route path='impacts' element={<Impacts />} />
							<Route path='impacts/:id' element={<Impact />} />
							<Route path='initiatives' element={<Initiatives />} />
							<Route path='initiatives/:id' element={<Initiative />} />
							<Route path='goals' element={<Goals />} />
							<Route path='goals/:id' element={<Goal />} />
						</Route>
					</Route>
					<Route path='/*' element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
