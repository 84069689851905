import { apiSlice } from "app/api";

export const tasksApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Tasks"],
  endpoints: (builder) => ({
		addTask: builder.mutation({
			query: ({ ...body }) => ({
				url: `/tasks/`,
				method: "POST",
				...body,
			}),
			invalidatesTags: ["Tasks"],
		}),
    updateTask: builder.mutation({
      query: ({ taskId, ...body }) => ({
        url: `/tasks/${taskId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Tasks"],
    }),
    deleteTask: builder.mutation({
      query: (taskId) => ({
        url: `/tasks/${taskId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tasks"],
    }),
    getConsultantTasks: builder.query({
      query: (consultantId) => `/tasks/task_consultant/${consultantId}`,
    }),
    getCustomerTasks: builder.query({
      query: (customerId) => `/tasks/task_customer/${customerId}`,
    }),
    getGoalTasks: builder.query({
      query: (goalId) => `/tasks/task_goal/${goalId}`,
    }),
    getVendorTasks: builder.query({
      query: (vendorId) => `/tasks/task_vendor/${vendorId}`,
    }),
  }),
});

export const {
  useAddTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useGetConsultantTasksQuery,
  useGetCustomerTasksQuery,
  useGetGoalTasksQuery,
  useGetVendorTasksQuery,
} = tasksApiSlice;
