import { Table } from "common";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import FormHandler from "../common/formHandler/FormHandler";
import {
  useGetConsultantGoalsQuery,
  useGetAccountGoalsQuery,
  useDeleteGoalMutation,
} from "features/goals/goalsSlice";
import { store } from "app/store";
import { createColumnHelper } from "@tanstack/react-table";
import { useGetUserListQuery } from "features/user/userSlice";
import { GoalForm } from "features/goals";

function Goals() {
  let userList = [];
  let goalsListByConsultant = [];
  let goalsListByAccount = [];
  let consultantId;
  let accountId;
  const [model, setModel] = useState(false);
  const [goalId, setGoalId] = useState();
  const [deleteGoalName, setDeleteGoalName] = useState("");
  const [viewAll, setviewAll] = useState("MINE");
  const DELETE_DIALOG_TITLE = "Goals";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete "${deleteGoalName}" this goal?`;

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;
  if (store.getState().auth.userData === null) accountId = 0;
  else accountId = store.getState().auth.userData.organization;

  const { data: goalByConsultantInfo, isSuccess: isGoalByConsultantSuccess } =
    useGetConsultantGoalsQuery(consultantId, {
      refetchOnMountOrArgChange: true,
    });

  if (isGoalByConsultantSuccess) {
    goalsListByConsultant = goalByConsultantInfo.data;
  }

  const { data: goalByAccountInfo, isSuccess: isGoalByAccountSuccess } =
    useGetAccountGoalsQuery(accountId, {
      refetchOnMountOrArgChange: true,
    });
  if (isGoalByAccountSuccess) {
    goalsListByAccount = goalByAccountInfo.data;
  }

  const [deleteGoal] = useDeleteGoalMutation();

  const handleUserList = useGetUserListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUserList.isSuccess === true) {
    userList = handleUserList.data.data;
  }

  const deleteDialog = (goalId, goalName) => {
    setGoalId(goalId);
    setDeleteGoalName(goalName);
    return setModel(true);
  };

  const hideDialog = () => {
    setModel(false);
  };

  const deleteHandle = async () => {
    if (goalId) {
      await deleteGoal(goalId);
      setGoalId(null);
      setDeleteGoalName("");
    }

    setModel(false);
  };

  const formatDate = (inputDate) => {
    if (inputDate) {
      let [year, month, day] = inputDate.split("-").map(Number);
      let formatedDate = new Date(year, month - 1, day);
      return formatedDate.toDateString();
    }
  };

  const formatOwnerConsultant = (id) => {
    if (id) {
      const consultant = userList.find((user) => user.id === id);
      return consultant
        ? `${consultant.first_name} ${consultant.last_name}`
        : "";
    }
    return "";
  };

  let defaultData = [];

  if (viewAll === "ALL") {
    defaultData = goalsListByAccount || [];
  }
  if (viewAll === "MINE") {
    defaultData = goalsListByConsultant || [];
  }
  const columnHelper = createColumnHelper();

  const columnsAll = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/goals/${info.row.original.id}`}
          state={{ goalId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Not-Started" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : info.row.original.stage === "In-Progress" ? (
            <span className='badge text-bg-warning'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),

    columnHelper.accessor((row) => row.owner_consultant, {
      id: "owner_consultant",
      cell: (info) => formatOwnerConsultant(info.getValue()),
      header: () => <span>Owner Consultant</span>,
      show: viewAll === "ALL",
    }),

    columnHelper.accessor((row) => row.vendor_name, {
      id: "vendor_name",
      cell: (info) => (
        <Link
          to={`/clients/${info.row.original.vendor_account}`}
          state={{ clientId: info.row.original.vendor_account }}
          style={{ textDecoration: 'none'}}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Belongs To</span>,
    }),
    
    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <GoalForm
              formInputs={{
                formType: "Goal",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() =>
                deleteDialog(info.row.original.id, info.row.original.title)
              }
            ></i>
          </span>
        </div>
      ),
    },
  ];

  const columnsMine = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/goals/${info.row.original.id}`}
          state={{ goalId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Not-Started" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : info.row.original.stage === "In-Progress" ? (
            <span className='badge text-bg-warning'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),

    columnHelper.accessor((row) => row.vendor_name, {
      id: "vendor_name",
      cell: (info) => (
        <Link
          to={`/clients/${info.row.original.vendor_account}`}
          state={{ clientId: info.row.original.vendor_account }}
          style={{ textDecoration: 'none'}}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Belongs To</span>,
    }),

    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <GoalForm
              formInputs={{
                formType: "Goal",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() =>
                deleteDialog(info.row.original.id, info.row.original.title)
              }
            ></i>
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <h1 style={{ margin: "0" }}>Goals</h1>
        </div>
        <div className='col-1 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View all'
              checked={viewAll === "ALL"}
              onChange={() => {
                setviewAll("ALL");
              }}
            />
          </Form>
        </div>
        <div className='col-2 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View mine'
              checked={viewAll === "MINE"}
              onChange={() => {
                setviewAll("MINE");
              }}
            />
          </Form>
        </div>
        <div className='col text-end pr-5'>
          <GoalForm
            formInputs={{
              formType: "Goal",
              formAction: "Add",
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {viewAll === "ALL" ? (
            <Table
              defaultData={defaultData}
              columns={columnsAll}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          ) : (
            <Table
              defaultData={defaultData}
              columns={columnsMine}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Goals;
