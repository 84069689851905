import { Link } from "react-router-dom";
import { useGetImpactQuery } from "./impactsSlice";

const ImpactBreadCrumb = ({ impactId }) => {
  let impactInfo = [];

  const { data, isSuccess, isError } = useGetImpactQuery(impactId, {
    refetchOnMountOrArgChange: true,
  });

  if (isError) {
    return <div>Error in loading data!</div>;
  }

  if (isSuccess) {
    impactInfo = data.data;
  }

  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb'>
        <li className='breadcrumb-item'>
          <Link
            to={`/clients/${impactInfo.vendor_account}`}
            state={{ clientId: `${impactInfo.vendor_account}` }}
          >
            Client Detail
          </Link>
        </li>
        <li className='breadcrumb-item' key={impactInfo.goal}>
          <Link
            to={`/goals/${impactInfo.goal}`}
            state={{ goalId: `${impactInfo.goal}` }}
          >
            Goal Detail
          </Link>
        </li>
        <li className='breadcrumb-item' key={impactInfo.id}>
          <Link
            to={`/initiatives/${impactInfo.initiative}`}
            state={{ initiativeId: `${impactInfo.initiative}` }}
          >
            Initiative Detail
          </Link>
        </li>
        <li className='breadcrumb-item active' key={impactInfo.id}>
          Impact Detail
        </li>
      </ol>
    </nav>
  );
};

export default ImpactBreadCrumb;
