import loginbg from "features/ui/svgs/target-bkgd.svg";
import { Auth } from "features/auth";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { authData } from "features/auth/authSlice";


function Login() {
  const isLoggedIn = (auth) => {
    let local_auth = JSON.parse(localStorage.getItem("auth"));
    return local_auth &&
      auth &&
      local_auth.token &&
      auth.token &&
      local_auth.data &&
      auth.userData &&
      local_auth.data.email &&
      auth.userData.email
      ? true
      : false;
  };
  let auth = useSelector(authData);

  return (
    <>
	{!isLoggedIn(auth)?
      <div
        id='login'
        style={{
          background: `url(${loginbg}) no-repeat center center`,
          backgroundColor: "lightslategray",
          backgroundSize: "cover",
        }}
      >
        <Auth />
        {/* comment out the below signup link for now

				<div className='text-center mt-3'>
					<p className='text-white'>
						Don't have an account?&nbsp;&nbsp;
						<Link to='/signup' className='text-white'>
							Sign up
						</Link>
					</p>
				</div>

				*/}
      </div>: <Navigate to="/"/>}
    </>
  );
}

export default Login;
