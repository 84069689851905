import { useState } from "react";
import { ContactsList } from "features/contacts";
import { useNavigate, useParams } from "react-router-dom";
import { useGetImpactQuery } from "features/impacts/impactsSlice";
import MultiTypeChart from "common/charts/MultiTypeChart";
//import FocusScoreChart from "features/focus-score/FocusScoreChart";
import { store } from "app/store";
import { ImpactBreadCrumb, ImpactDataPoints } from "features/impacts";
import Attachment from "common/attachments/Attachment";
import {
  useGetImpactAttachmentsQuery,
  useAddImpactAttachmentsMutation,
  useDeleteAttachmentMutation,
} from "common/attachments/attachmentSlice";
import FormHandler from "common/formHandler/FormHandler";
import {
  useGetCommentsQuery,
  useAddCommentsMutation,
} from "common/comments/commentSlice";
import { Comment } from "common";
import { useDeleteImpactMutation } from "features/impacts/impactsSlice";
import { DeleteDialog } from "common";
import { useGetHierarchyByImpactQuery } from "features/goals/goalsSlice";
import { Hierarchy, Dates } from "common";
import { FocusScore } from "features/focus-score";

function Impact() {
  let impactData = [];
  let userId;
  let attachmentsInfo = [];
  let impactHierarchy = [];

  //Giving the userId as 0 if user is not logged in.It is temporary.
  if (store.getState().auth.userData === null) userId = 0;
  else userId = store.getState().auth.userData.id;

  const navigate = useNavigate();

  const { id } = useParams(); // get 'id' from path param
  const impactId = id; // assign id to impactId
  const [inventoryId, setInventoryId] = useState(1);
  let impactComments = [];
  let type = "impact";
  let parentId = impactId;

  const [model, setModel] = useState(false);

  const [selectedImpactDetails, setSelectedImpactDetails] = useState({
    impactId: "",
    impactTitle: "",
  });

  const hideDialog = () => {
    setModel(false);
    navigate("/impacts");
  };

  const handleChange = (inventoryId) => {
    setInventoryId(inventoryId);
  };

  const { data: impactDetails, isSuccess } = useGetImpactQuery(impactId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess && impactDetails.data) {
    impactData = impactDetails.data;
  }

  const DELETE_DIALOG_TITLE = "Impact: Delete";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete "${selectedImpactDetails.title}" impact?`;

  const impactAttachmentsData = useGetImpactAttachmentsQuery(impactId, {
    refetchOnMountOrArgChange: true,
  });

  if (impactAttachmentsData.isSuccess) {
    attachmentsInfo = impactAttachmentsData.data.attachments;
  }

  const [addImpactAttachemnts] = useAddImpactAttachmentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleAddAttachment = async (formData) => {
    formData.append("impact_id", impactId);
    formData.append("user_id", userId);
    await addImpactAttachemnts(formData);
  };

  const [deleteImpactAttachemnts] = useDeleteAttachmentMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleDeleteAttachment = async (attachmentId) => {
    await deleteImpactAttachemnts(attachmentId);
  };

  const { data: impactHierarchyData, isSuccess: isImpactHierarchySuccess } =
    useGetHierarchyByImpactQuery(impactId, {
      refetchOnMountOrArgChange: true,
    });

  if (isImpactHierarchySuccess && impactHierarchyData.data) {
    impactHierarchy = impactHierarchyData.data;
  }

  const renderInventoryContent = () => {
    switch (inventoryId) {
      case 1:
        return <Hierarchy hierarchy={impactHierarchy} />;
      case 2:
        return (
          <Dates
            created={impactData.created_on_date}
            started={impactData.start_date}
            ended={impactData.end_date}
            lastModified={impactData.modified_on_date}
          />
        );
      case 3:
        return <ImpactDataPoints impactId={impactId} />;
      case 4:
        return <ContactsList />;
      default:
        return (
          <Attachment
            attachmentsInfo={attachmentsInfo}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        );
    }
  };

  const renderHealthCheck = (impactId) => {
    return <MultiTypeChart impactId={impactId} consultantId={userId} />;
  };

  const renderFocusScore = (impactId) => {
    // return <FocusScoreChart user_Id={userId} impact_Id={impactId} />;
    return <FocusScore userid={userId} impactid={impactId} />;
  };

  const deleteImpactDialog = (impactId, impactTitle) => {
    setSelectedImpactDetails({ impactId: impactId, impactTitle: impactTitle });
    return setModel(true);
  };
  const [deleteImpact] = useDeleteImpactMutation();

  const renderImpactDetail = (title, description, statusText) => {
    return (
      <div className='card p-4'>
        <div className='card-body d-flex flex-column'>
          <div className='row'>
            <div className='col-1'>
              <i className='bi bi-fire icon-title'></i>
            </div>
            <div className='col'>
              <h1 className='impact-title'>{title}</h1>
            </div>
          </div>
          <div className='impact-status'>
            {statusText === "Not-Started" ? (
              <span className='badge text-bg-danger small'>{statusText}</span>
            ) : statusText === "In-Progress" ? (
              <span className='badge text-bg-warning small'>{statusText}</span>
            ) : (
              <span className='badge text-bg-success small'>{statusText}</span>
            )}

            <div>
              <FormHandler
                formInputs={{
                  formType: "Impact",
                  formAction: "Edit",
                  itemDetails: {
                    id: impactId,
                  },
                }}
              />
              <span className='action-item'>
                <i
                  className='bi bi-trash-fill'
                  data-bs-placement='top'
                  title='Delete'
                  onClick={() => deleteImpactDialog(impactId, title)}
                ></i>
              </span>
            </div>
          </div>
          <p className='impact-description'>{description}</p>
          <div className='impact-icon-group'>
            <button className='btn-basic' onClick={() => handleChange(1)}>
              <i
                className={
                  "bi bi-diagram-3 " + (inventoryId === 1 ? "active" : "")
                }
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(2)}>
              <i
                className={
                  "bi bi-calendar-date " + (inventoryId === 2 ? "active" : "")
                }
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(3)}>
              <i
                className={
                  "bi bi-bar-chart-steps " + (inventoryId === 3 ? "active" : "")
                }
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(4)}>
              <i
                className={
                  "bi bi-person-square " + (inventoryId === 4 ? "active" : "")
                }
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(5)}>
              <i
                className={
                  "bi bi-paperclip " + (inventoryId === 5 ? "active" : "")
                }
              ></i>
            </button>
          </div>
          <div id='inventory-content' className='mt-4'>
            {renderInventoryContent()}
          </div>
        </div>
      </div>
    );
  };

  const { data: impactCommentData, isSuccess: isCommentSuccess } =
    useGetCommentsQuery(
      { type, parentId },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  if (isCommentSuccess && impactCommentData.comments) {
    impactComments = impactCommentData.comments;
  }
  const [addImpactComments] = useAddCommentsMutation({
    refetchOnMountOrArgChange: true,
  });
  const handleCommentSubmit = async (formData) => {
    formData.append("parent_id", parentId);
    formData.append("user_id", userId);
    formData.append("type", type);
    await addImpactComments(formData);
  };

  return (
    <>
      <div className='container g-4'>
        <div className='row'>
          <ImpactBreadCrumb impactId={impactId} />
        </div>
        <div id='impact-detail' className='row'>
          <div className='col-4'>
            {renderImpactDetail(
              impactData.title,
              impactData.description,
              impactData.stage
            )}
          </div>
          <div className='col'>
            <div className='row mb-4'>
              <div className='col'>
                <div className='card'>
                  <div className='card-header'>
                    Health Check - Target vs Actual
                  </div>
                  <div className='card-body'>{renderHealthCheck(impactId)}</div>
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col'>
                <div className='card'>
                  <div className='card-header'>Focus Scores</div>
                  <div className='card-body'>{renderFocusScore(impactId)}</div>
                </div>
              </div>
            </div>
            <Comment
              comments={impactComments}
              handleCommentSubmit={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
      {model === true ? (
        <DeleteDialog
          dialogTitle={DELETE_DIALOG_TITLE}
          descriptionText={DELETE_DIALOG_MESSAGE}
          hide={() => hideDialog()}
          handleDelete={() => deleteImpact(impactId)}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Impact;
