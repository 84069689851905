import React from "react";
import { useGetImpactDataPointsByImpactIdQuery } from "./impactsSlice";

const ImpactDataPoints = ({ impactId }) => {
	let impactDataPoints = [];
	const { data, isSuccess } = useGetImpactDataPointsByImpactIdQuery(impactId, {
		refetchOnMountOrArgChange: true,
	});

	if (!isSuccess || !data.data) {
		return null;
	} else {
		impactDataPoints = data.data;
	}

	function convertDateToMonth(dateStr) {
		const date = new Date(dateStr);
		return date.toLocaleDateString("default", { month: "long" });
	}

	return (
		<div className='container py-2'>
			<div className='row d-flex justify-content-between mw-100'>
				<div className='col fw-bold'>Data Points</div>
				<div className='col fw-bold'>Actual</div>
			</div>
			{impactDataPoints.map((dataPoint, idx) => {
				return (
					<div className='row d-flex justify-content-between mw-100' key={idx}>
						<div className='col inventory-item'>
							{convertDateToMonth(dataPoint.date)}
						</div>
						<div className='col inventory-item'>{dataPoint.actual_value}</div>
					</div>
				);
			})}
		</div>
	);
};

export default ImpactDataPoints;
