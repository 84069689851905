import {
  useAddGoalMutation,
  useUpdateGoalMutation,
  useGetGoalsQuery,
} from "features/goals/goalsSlice";
import FormHandler from "common/formHandler/FormHandler";
import { skipToken } from "@reduxjs/toolkit/query";

function GoalForm({ formInputs }) {
  let goalData;
  const getGoalData = useGetGoalsQuery(
    (formInputs && formInputs.itemDetails && formInputs.itemDetails.id) ||
      skipToken
  );

  const [addGoal] = useAddGoalMutation({
    refetchOnMountOrArgChange: true,
  });
  const [updateGoal] = useUpdateGoalMutation({
    refetchOnMountOrArgChange: true,
  });

  if (getGoalData.isSuccess) {
    goalData = getGoalData.data.data;
  }

  return (
    <FormHandler
      formInputs={formInputs}
      addGoal={addGoal}
      updateGoal={updateGoal}
      goalData={goalData}
    />
  );
}

export default GoalForm;
