import { apiSlice } from "app/api";

const impactHistorySlice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getImpactHistory: build.query({
			query: (ownerConsultantId) => `/impacts/history/${ownerConsultantId}`,
			providesTags: ["Impact"],
		}),
		getImpactHistoryByRange: build.query({
			query: (ownerConsultantId, startDate, endDate) =>
				`/impacts/history/${ownerConsultantId}?startDate=${startDate}&endDate=${endDate}`,
			providesTags: ["Impact"],
		}),
		overrideExisting: false,
	}),
});

export const { useGetImpactHistoryQuery, useGetImpactHistoryByRangeQuery } =
	impactHistorySlice;
