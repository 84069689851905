import { Link } from "react-router-dom";

function Hierarchy({ hierarchy }) {
  let title = hierarchy.title ? hierarchy.title : hierarchy.goal?.title;
  const handleStage = (stage) => {
    if (stage === null || stage === undefined) {
      return null;
    } else {
      return (
        <i
          className={`bi bi-circle-fill me-2 ${
            stage === "Not-Started"
              ? "text-danger"
              : stage === "In-Progress"
              ? "text-warning"
              : "text-success"
          }`}
        ></i>
      );
    }
  };

  const renderLink = (type, id, title) => {
    return (
      <Link
        to={`/${type}s/${id}`}
        state={{ [`${type}Id`]: id }}
        className='custom-link'
      >
        {title}
      </Link>
    );
  };

  return (
    <>
      <ul>
        <li className='root'>
          <div className='d-flex hierarchy-list-item'>
            {handleStage(hierarchy.goal?.stage)}
            <p
              className={`inventory-item ${
                title === hierarchy.goal?.title ? "fw-bold" : ""
              }`}
            >
              {renderLink("goal", hierarchy.goal?.id, hierarchy.goal?.title)}
            </p>
          </div>
        </li>
        {hierarchy.initiatives?.map((initiative, i) => (
          <li key={i + 1}>
            <div className='d-flex hierarchy-list-item'>
              {handleStage(initiative.stage)}
              <p
                className={`inventory-item ${
                  title === initiative.title ? "fw-bold" : ""
                }`}
              >
                {renderLink("initiative", initiative.id, initiative.title)}
              </p>
            </div>
            {initiative.impacts.length > 0 && (
              <ul>
                {initiative.impacts.map((impact) => (
                  <li key={impact.id + 2}>
                    <div className='d-flex hierarchy-list-item'>
                      <p
                        className={`inventory-item ${
                          title === impact.title ? "fw-bold" : ""
                        }`}
                      >
                        {renderLink("impact", impact.id, impact.title)}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {initiative.projects.length > 0 && (
              <ul>
                {initiative.projects.map((project, i) => (
                  <li key={i + 5}>
                    <div className='d-flex hierarchy-list-item'>
                      {handleStage(project.stage)}
                      <p
                        className={`inventory-item ${
                          title === project.title ? "fw-bold" : ""
                        }`}
                      >
                        {renderLink("project", project.id, project.title)}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

export default Hierarchy;
