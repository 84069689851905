import React from "react";
import { ContactsList } from "features/contacts";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetInitiativeQuery } from "./initiativesSlice";
import {
  useGetInitiativeAttachmentsQuery,
  useAddInitiativeAttachmentsMutation,
  useDeleteAttachmentMutation,
} from "common/attachments/attachmentSlice";
import Attachment from "common/attachments/Attachment";
import { store } from "app/store";
import { DeleteDialog } from "common";
import { useDeleteInitiativeMutation } from "./initiativesSlice";
import { useGetInitiativeHierarchyQuery } from "features/initiatives/initiativesSlice";
import { Hierarchy, Dates, Budget } from "common";
import { InitiativeForm } from "features/initiatives";
import { skipToken } from "@reduxjs/toolkit/query";

function InitiativeDetail() {
  let initInfo = [];
  let attachmentsInfo = [];
  let consultantId;
  let initHierarchy = [];

  const [inventoryId, setInventoryId] = useState(1);
  const location = useLocation();
  const { initiativeId } = (location && location.state) || {};
  const navigate = useNavigate();
  const [model, setModel] = useState(false);
  const [initiativeDetails, setInitiativeDetails] = useState({
    initiativeId: "",
    initiativeTitle: "",
  });
  const [deleteInitiative] = useDeleteInitiativeMutation();

  const DELETE_DIALOG_TITLE = "Initiative: Delete";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete "${initiativeDetails.initiativeTitle}" initiative?`;

  const handleChange = (inventoryId) => {
    setInventoryId(inventoryId);
  };
  const { data, isSuccess, isError } = useGetInitiativeQuery(
    initiativeId ? initiativeId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (isSuccess) {
    initInfo = data.data;
    attachmentsInfo = data.attachments;
  }

  const initiativeAttachmentsData = useGetInitiativeAttachmentsQuery(
    initiativeId ? initiativeId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (initiativeAttachmentsData.isSuccess) {
    attachmentsInfo = initiativeAttachmentsData.data.attachments;
  }

  const [addInitiativeAttachemnts] = useAddInitiativeAttachmentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const { data: initHierarchyData, isSuccess: isInitHierarchySuccess } =
    useGetInitiativeHierarchyQuery(initiativeId ? initiativeId : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (isInitHierarchySuccess && initHierarchyData.data) {
    initHierarchy = initHierarchyData.data;
  }
  if (store.getState().auth.userData) {
    consultantId = store.getState().auth.userData.id;
  }
  const handleAddAttachment = async (formData) => {
    formData.append("initiative_id", initiativeId);
    formData.append("user_id", consultantId);
    await addInitiativeAttachemnts(formData);
  };

  const [deleteAttachemnt] = useDeleteAttachmentMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleDeleteAttachment = async (attachmentId) => {
    await deleteAttachemnt(attachmentId);
  };

  if (isError) {
    return null;
  }

  const deleteInitiativeDialog = (initiativeId, initiativeTitle) => {
    setInitiativeDetails({
      initiativeId: initiativeId,
      initiativeTitle: initiativeTitle,
    });
    return setModel(true);
  };

  const hideDialog = () => {
    setModel(false);
    navigate("/initiatives");
  };

  const renderInventoryContent = () => {
    switch (inventoryId) {
      case 1:
        return <Hierarchy hierarchy={initHierarchy} />;
      case 2:
        return (
          <Dates
            created={initInfo.created_on_date}
            started={initInfo.start_date}
            ended={initInfo.end_date}
            lastModified={initInfo.modified_on_date}
          />
        );
      case 3:
        return (
          <Budget
            allocatedBudget={initInfo.allocated_budget}
            currentSpend={initInfo.spend_budget}
          />
        );
      case 4:
        return <ContactsList />;
      case 5:
        return (
          <Attachment
            attachmentsInfo={attachmentsInfo}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className='col-4'>
      <div className='card p-4'>
        <div className='card-body d-flex flex-column'>
          <div className='row'>
            <div className='col-1'>
              <i className='bi bi-lightning icon-title'></i>
            </div>
            <div className='col'>
              <h1 className='initiative-title'>{initInfo.title}</h1>
            </div>
          </div>
          <div className='initiative-status'>
            {initInfo.stage === "Not-Started" ? (
              <span className='badge text-bg-danger small'>
                {initInfo.stage}
              </span>
            ) : initInfo.stage === "In-Progress" ? (
              <span className='badge text-bg-warning small'>
                {initInfo.stage}
              </span>
            ) : (
              <span className='badge text-bg-success small'>
                {initInfo.stage}
              </span>
            )}
            <div>
              <InitiativeForm
                formInputs={{
                  formType: "Initiative",
                  formAction: "Edit",
                  itemDetails: {
                    id: initInfo.id,
                  },
                }}
              />
              <i
                className='bi bi-trash-fill'
                data-bs-placement='top'
                title='Delete'
                onClick={() =>
                  deleteInitiativeDialog(initInfo.id, initInfo.title)
                }
              ></i>
            </div>
          </div>
          <p className='initiative-description'>{initInfo.description}</p>
          <div className='initiative-icon-group'>
            <button className='btn-basic' onClick={() => handleChange(1)}>
              <i
                className={`bi bi-diagram-3 ${
                  inventoryId === 1 ? "active" : ""
                }`}
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(2)}>
              <i
                className={`bi bi-calendar-date ${
                  inventoryId === 2 ? "active" : ""
                }`}
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(3)}>
              <i
                className={`bi bi-coin ${inventoryId === 3 ? "active" : ""}`}
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(4)}>
              <i
                className={`bi bi-person-square ${
                  inventoryId === 4 ? "active" : ""
                }`}
              ></i>
            </button>
            <button className='btn-basic' onClick={() => handleChange(5)}>
              <i
                className={`bi bi-paperclip ${
                  inventoryId === 5 ? "active" : ""
                }`}
              ></i>
            </button>
          </div>
          <div id='inventory-content' className='mt-4'>
            {renderInventoryContent()}
          </div>
        </div>
      </div>
      {model === true ? (
        <DeleteDialog
          dialogTitle={DELETE_DIALOG_TITLE}
          descriptionText={DELETE_DIALOG_MESSAGE}
          hide={() => hideDialog()}
          handleDelete={() => deleteInitiative(initiativeDetails.initiativeId)}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default InitiativeDetail;
