import { useDispatch } from "react-redux";

import { logOut } from "features/auth/authSlice";

const ErrorHandler = ({ error, onClose }) => {
  let errorMessage = error.error;
  const dispatch = useDispatch();
  switch (error.error.status) {
    case 401:
      errorMessage = "Unauthorized! Please log in and try again.";
      dispatch(logOut());
      break;
    case 403:
      errorMessage = "You dont have permission to perform this action";
      break;
    case 404:
      errorMessage = "The requested resource can not be found";
      break;
    case 500:
      errorMessage = "An internal server error has occured. Please try again!";
      break;
    default:
      errorMessage = errorMessage.data.message.title || "An error has occurred";
  }
  return (
    <div
      className='toast show position-fixed top-0 start-50 translate-middle-x'
      role='alert'
      aria-live='assertive'
      aria-atomic='true'
      style={{ zIndex: 1100 }}
    >
      <div className='toast-header custom-toast-header'>
        <strong className='error-text'>Error</strong>
        <button
          type='button'
          className='close'
          data-dismiss='toast'
          aria-label='Close'
          onClick={onClose}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='toast-body'>{errorMessage}</div>
    </div>
  );
};

export default ErrorHandler;
