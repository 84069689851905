import { apiSlice } from "app/api";

const attachmentSlice = apiSlice.injectEndpoints({
	endpoints : (build) => ({
		getGoalAttachments: build.query({
			query: (goalId) => `/attachments/goal/${goalId}`,
			providesTags: ["Attachment"],
		}),
		getInitiativeAttachments: build.query({
			query: (initiativeId) => `/attachments/initiative/${initiativeId}`,
			providesTags: ["Attachment"]
		}),
		getImpactAttachments: build.query({
			query: (impactId) => `/attachments/impact/${impactId}`,
			providesTags: ["Attachment"]
		}),
		getProjectAttachments: build.query({
			query: (projectId) => `/attachments/project/${projectId}`,
			providesTags: ["Attachment"]
		}),
		getTaskAttachments: build.query({
			query: (taskId) => `/attachments/task/${taskId}`,
			providesTags: ["Attachment"]
		}),
		getAccountAttachments: build.query({
			query: (accountId) => `/attachments/account/${accountId}`,
			providesTags: ["Attachment"]
		}),
		addGoalAttachments: build.mutation({
			query: (formData) => ({
				url: `/attachments/goals/`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Attachment"],
		}),
		addInitiativeAttachments: build.mutation({
			query: (formData) => ({
				url: `/attachments/initiatives/`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Attachment"],
		}),
		addImpactAttachments: build.mutation({
			query: (formData) => ({
				url: `/attachments/impacts/`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Attachment"],
		}),
		addProjectAttachments: build.mutation({
			query: (formData) => ({
				url: `/attachments/projects/`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Attachment"],
		}),
		addTaskAttachments: build.mutation({
			query: (formData) => ({
				url: `/attachments/tasks/`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Attachment"],
		}),
		addAccountAttachments: build.mutation({
			query: (formData) => ({
				url: `/attachments/account/`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Attachment"],
		}),
		deleteAttachment: build.mutation({
			query: (attachmentId) => ({
				url: `/attachments/${attachmentId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Attachment"]
		}),
		overrideExisting: false,
	}),
});


export const { 
	useGetGoalAttachmentsQuery,
	useGetInitiativeAttachmentsQuery,
	useGetImpactAttachmentsQuery,
	useGetProjectAttachmentsQuery,
	useGetTaskAttachmentsQuery,
	useGetAccountAttachmentsQuery,
	useAddGoalAttachmentsMutation,
	useAddInitiativeAttachmentsMutation,
	useAddImpactAttachmentsMutation,
	useAddProjectAttachmentsMutation,
	useAddTaskAttachmentsMutation,
	useAddAccountAttachmentsMutation,
	useDeleteAttachmentMutation
} = attachmentSlice;