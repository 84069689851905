import { NewsItem } from "features/news";
import { useGetNewsFeedQuery } from "features/news/newsApiSlice";
import { store } from "app/store";

function NewsList() {
	let feedLists = [];
	let userId;
	const feedCount = 3;

	//Giving the userId as 0 if user is not logged in.It is temporary.
	if (store.getState().auth.userData === null) userId = 0;
	else userId = store.getState().auth.userData.id;
	const { data, isSuccess, isError } = useGetNewsFeedQuery(userId);
	if (isSuccess) {
		feedLists = data.feedlist;
	}
	if (isError) {
		return null;
	}

	return (
		<>
			<div className='list-group list-group-flush'>
				{feedLists && feedLists.length > 0 ? (
					feedLists.map((feedList, i) => {
						if (i < feedCount) {
							return (
								<NewsItem
									key={i}
									title={feedList.feedtitle}
									comment={feedList.comment}
									date={feedList.created_on_date}
								/>
							);
						} else return null;
					})
				) : (
					<p>News Feed does not exist for the consultant</p>
				)}
			</div>
		</>
	);
}

export default NewsList;
