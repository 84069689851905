import logo from "features/ui/fdic-logo.png";

function UserProfile() {
	return (
		<>
			<div className='container g-4'>
				<div className='row'>
					<div className='col-4'>
						<div className='card p-4' id='user-profile'>
							<div className='card-body d-flex align-items-center flex-column'>
								<div className='profile-full bg-primary my-4'>TM</div>
								<span className='user-display-name'>Thomas Morrow</span>
								<span className='user-phone'>
									office: +1 (407)987-1234 ext: 13
								</span>
								<span className='user-phone'>cell: +1 (407)383-9704</span>
								<span className='user-email'>email: tom.morrow@clever.io</span>
								<div className='social-icon-group'>
									<i className='bi bi-linkedin active'></i>
									<i className='bi bi-slack active'></i>
									<i className='bi bi-microsoft-teams'></i>
									<i className='bi bi-skype active'></i>
								</div>
								<span className='profile-section-title'>Company Info:</span>
								<div className='d-flex align-items-center mb-4'>
									<div className='flex-shrink-0'>
										<img src={logo} alt='' className='company-profile me-3' />
									</div>
									<div className='flex-grow-1'>
										<p className='company-name'>
											Federal Deposit Insurance Corporation
										</p>
										<p className='text-muted small'>US Federal Government</p>
									</div>
								</div>
								<div className='company-phone mb-3'>
									<p className='title'>Main line:</p>
									<p>+1 (407)987-1234</p>
								</div>
								<div className='address mb-3'>
									<p className='title'>Address:</p>
									<p>1313 Mockingbird Ln; Suite 100</p>
									<p>San Francisco, CA 94103</p>
								</div>
								<div className='address mb-3'>
									<p className='title'>Website:</p>
									<p>http://www.fdic.gov</p>
								</div>
							</div>
						</div>
					</div>
					<div className='col'>
						<div className='card'>
							<div className='card-body'>
								<div id='personal-info' className='mb-5'>
									<section className='mb-2'>
										<span className='profile-section-title'>
											Personal Info:
										</span>
									</section>
									<form action=''>
										<div className='row mb-3'>
											<div className='col'>
												<label
													htmlFor='first_name'
													className='form-label small mb-0'
												>
													First name
												</label>
												<input
													id='first_name'
													type='text'
													className='form-control form-control-sm'
													placeholder='Thomas'
													aria-label='First name'
												/>
											</div>
											<div className='col'>
												<label
													htmlFor='last_name'
													className='form-label small mb-0'
												>
													Last name
												</label>
												<input
													id='last_name'
													type='text'
													className='form-control form-control-sm'
													placeholder='Morrow'
													aria-label='Last name'
												/>
											</div>
										</div>
										<div className='row mb-3'>
											<div className='col'>
												<label
													htmlFor='office_phone'
													className='form-label small mb-0'
												>
													Office phone
												</label>
												<input
													id='office_phone'
													type='text'
													className='form-control form-control-sm'
													placeholder='(407)987-1234'
													aria-label='Office phone'
												/>
											</div>
											<div className='col'>
												<label
													htmlFor='mobile_phone'
													className='form-label small mb-0'
												>
													Mobile phone
												</label>
												<input
													id='mobile_phone'
													type='text'
													className='form-control form-control-sm'
													placeholder='(407)383-9704'
													aria-label='Mobile phone'
												/>
											</div>
										</div>
										<div className='row mb-3'>
											<div className='col-6'>
												<label
													htmlFor='e-mail'
													className='form-label small mb-0'
												>
													e-mail
												</label>
												<input
													id='e-mail'
													type='text'
													className='form-control form-control-sm'
													placeholder='tom.morrow@clever.io'
													aria-label='e-mail'
												/>
											</div>
										</div>
									</form>
								</div>
								<div id='social-info' className='mb-5'>
									<section className='mb-2'>
										<span className='profile-section-title'>Social Info:</span>
									</section>
									<form action=''>
										<div className='row mb-3'>
											<div className='col'>
												<label
													htmlFor='linked-in'
													className='form-label small mb-0'
												>
													LinkedIn page url
												</label>
												<input
													id='linked-in'
													type='text'
													className='form-control form-control-sm'
													placeholder='https://www.linkedin.com/in/tom.morrow/'
													aria-label='LinkedIn page url'
												/>
											</div>
										</div>
										<div className='row mb-3'>
											<div className='col'>
												<label
													htmlFor='slack-id'
													className='form-label small mb-0'
												>
													Slack ID
												</label>
												<input
													id='slack-id'
													type='text'
													className='form-control form-control-sm'
													placeholder='tom.morrow'
													aria-label='Slack ID'
												/>
											</div>
											<div className='col'>
												<label
													htmlFor='skype-id'
													className='form-label small mb-0'
												>
													Skype ID
												</label>
												<input
													id='skype-id'
													type='text'
													className='form-control form-control-sm'
													placeholder='tom.morrow@clever.io'
													aria-label='Skype ID'
												/>
											</div>
										</div>
										<div className='row mb-3'>
											<div className='col-6'>
												<label
													htmlFor='teams-id'
													className='form-label small mb-0'
												>
													Teams ID
												</label>
												<input
													id='teams-id'
													type='text'
													className='form-control form-control-sm'
													placeholder='enter teams id here'
													aria-label='Teams ID'
												/>
											</div>
										</div>
									</form>
								</div>
								<div id='company-info' className='mb-5'>
									<section className='mb-2'>
										<span className='profile-section-title'>Company Info:</span>
									</section>
									<form action=''>
										<div className='row mb-4'>
											<div className='col'>
												<label
													htmlFor='company-name'
													className='form-label small mb-0'
												>
													Name
												</label>
												<select
													className='form-select form-select-sm'
													aria-label='Company'
												>
													<option selected>
														Federal Deposit Insurance Corporation
													</option>
													<option value='1'>One</option>
													<option value='2'>Two</option>
													<option value='3'>Three</option>
												</select>
											</div>
											<div className='col'>
												<label htmlFor='role' className='form-label small mb-0'>
													Role
												</label>
												<select
													className='form-select form-select-sm'
													aria-label='Role'
													disabled={true}
												>
													<option selected>Consultant</option>
													<option value='1'>One</option>
													<option value='2'>Two</option>
													<option value='3'>Three</option>
												</select>
											</div>
										</div>
										<button
											type='button'
											className='btn btn-primary btn-sm float-end text-light'
										>
											save all
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default UserProfile;
