import { useLocation } from "react-router-dom";
import { useGetInitiativeQuery } from "./initiativesSlice";
import { Link } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

function InitBreadCrumb() {
  let initInfo = [];
  const location = useLocation();

  const { initiativeId } = (location.state && location.state) || {};
  const { data, isSuccess } = useGetInitiativeQuery(
    initiativeId ? initiativeId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  if (isSuccess) {
    initInfo = data.data;
  }
  return (
    <ol className='breadcrumb'>
      <li className='breadcrumb-item'>
        <Link
          to={`/clients/${initInfo.vendor_account}`}
          state={{ clientId: `${initInfo.vendor_account}` }}
        >
          Client Detail
        </Link>
      </li>
      <li className='breadcrumb-item'>
        <Link
          to={`/goals/${initInfo.goal}`}
          state={{ goalId: `${initInfo.goal}` }}
        >
          Goal Detail
        </Link>
      </li>
      <li className='breadcrumb-item active' aria-current='page'>
        Initiative Detail
      </li>
    </ol>
  );
}

export default InitBreadCrumb;
