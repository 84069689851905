import { apiSlice } from "app/api";

const tasksSlice = apiSlice.injectEndpoints({
	tagTypes: ["Tasks"],
	endpoints: (build) => ({
		updateTask: build.mutation({
			query: ({ taskId, ...body }) => ({
				url: `/tasks/${taskId}`,
				method: "PATCH",
				...body,
			}),
			invalidatesTags: ["Tasks"],
		}),
		getTask: build.query({
			query: (taskId) => `/tasks/${taskId}`,
			providesTags: ["Tasks"],
		}),
		getAccountTasks: build.query({
			query: (accountId) => `/tasks/task_account/${accountId}`,
		}),
		getMyTasks: build.query({
			query: (userId) => `/tasks/assignedtasklist/${userId}`,
			providesTags: ["Tasks"],
		}),
		getInitiativeTasks: build.query({
			query: (initiativeId) => `/tasks/task_initiative/${initiativeId}`,
			providesTags: ["Tasks"],
		}),
		getProjectTasks: build.query({
			query: (projectId) => `/tasks/task_project/${projectId}`,
			providesTags: ["Tasks"],
		}),
		getVendorTasks: build.query({
			query: (vendorAccountId) => `/tasks/task_vendor/${vendorAccountId}`,
			providesTags: ["Tasks"],
		}),
		getConsultantTasks: build.query({
      query: (consultantId) => `/tasks/task_consultant/${consultantId}`,
    }),
	}),
});
export const {
	useUpdateTaskMutation,
	useGetTaskQuery,
	useGetAccountTasksQuery,
	useGetMyTasksQuery,
	useGetProjectTasksQuery,
	useGetInitiativeTasksQuery,
	useGetVendorTasksQuery,
	useGetConsultantTasksQuery
} = tasksSlice;