import { ContactItem } from "features/contacts";
function ContactsList() {
	return (
		<>
			<div className='list-group list-group-flush'>
				<ContactItem />
			</div>
		</>
	);
}

export default ContactsList;
