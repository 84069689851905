import { useGetTaskQuery, useUpdateTaskMutation } from "common/task/taskSlice";
import { useGetProjectQuery } from "features/projects/projectsSlice";
import { TaskCard, TaskTable } from "common";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { TaskCardEdit } from "common";
import { Link } from "react-router-dom";
import FormHandler from "common/formHandler/FormHandler";
import { DeleteDialog } from "common";
import { useDeleteTaskMutation } from "features/tasks/tasksSlice";

const DELETE_DIALOG_TITLE = "Task: Delete";
const DELETE_DIALOG_MESSAGE = "Are you sure you want to delete this task?";

function Task() {
  let task = [];
  let taskAttachments = [];
  let projectId;
  let projectTitle;
  const location = useLocation();
  const navigate = useNavigate();
  const [showEditForm, setShowEditForm] = useState(false);
  const taskId = location.state?.taskId;
  const [model, setModel] = useState(false);

  const [deleteTask] = useDeleteTaskMutation();

  const { data: taskDetail, isSuccess: isTaskDetailSuccess } = useGetTaskQuery(
    taskId,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (isTaskDetailSuccess) {
    task = taskDetail.data;
    projectId = task.project;
    taskAttachments = taskDetail.attachments;
  }

  const { data: projectData, isSuccess: isProjectSuccess } = useGetProjectQuery(
    projectId,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  if (isProjectSuccess) {
    projectTitle = projectData.data.title;
  }
  const [updateTask] = useUpdateTaskMutation({
    refetchOnMountOrArgChange: true,
  });

  const renderCrumbs = () => {
    return (
      <>
        <div className='row'>
          <nav aria-label='breadcrumb'>
            {location.state?.breadcrumb === "dashboard" ? (
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to={`/`}>Dashboard</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Task Detail
                </li>
              </ol>
            ) : (
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link
                    to={`/clients/${task.vendor_account}`}
                    state={{ clientId: `${task.vendor_account}` }}
                  >
                    Client Detail
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link
                    to={`/goals/${task.goal}`}
                    state={{ goalId: `${task.goal}` }}
                  >
                    Goal Detail
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link
                    to={`/initiatives/${task.initiative}`}
                    state={{ initiativeId: `${task.initiative}` }}
                  >
                    Initiative Detail
                  </Link>
                </li>
                {location.state?.breadcrumb === "project" ? (
                  <li className='breadcrumb-item'>
                    <Link
                      to={`/projects/${task.project}`}
                      state={{ projectId: `${task.project}` }}
                    >
                      Project Detail
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li className='breadcrumb-item active' aria-current='page'>
                  Task Detail
                </li>
              </ol>
            )}
          </nav>
        </div>
      </>
    );
  };

  const hideDialog = () => {
    setModel(false);
  };

  const deleteTaskHandle = async () => {
    if (taskId) {
      await deleteTask(taskId);
      navigate("/tasks");
    }
    setModel(false);
  };

  const deleteTaskDialog = () => {
    return setModel(true);
  };

  return (
    <>
      <div className='container g-4'>
        {renderCrumbs()}
        <div id='task-detail' className='row'>
          <div className='col-12'>
            <div id='tasks-details-card' className='card p-4'>
              <div className='card-body d-flex flex-column'>
                <i className='d-flex justify-content-end'>
                  <FormHandler
                    formInputs={{
                      formType: "Task",
                      formAction: "Edit",
                      itemDetails: {
                        id: taskId,
                      },
                    }}
                  />
                  <span className='action-item'>
                    <i
                      className='bi bi-trash-fill'
                      data-bs-placment='center'
                      title='Delete'
                      onClick={() => deleteTaskDialog()}
                    ></i>
                  </span>
                </i>

                {showEditForm ? (
                  <TaskCardEdit
                    task={task}
                    taskAttachments={taskAttachments}
                    linkedItem={projectTitle}
                    onStageChange={updateTask}
                    editForm={setShowEditForm}
                  />
                ) : (
                  <TaskCard
                    task={task}
                    taskAttachments={taskAttachments}
                    linkedItem={projectTitle}
                    onStageChange={updateTask}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div id='task-detail-table' className='row my-5'>
          <TaskTable />
        </div>
      </div>
      {model === true ? (
        <DeleteDialog
          dialogTitle={DELETE_DIALOG_TITLE}
          descriptionText={DELETE_DIALOG_MESSAGE}
          hide={() => hideDialog()}
          handleDelete={() => deleteTaskHandle()}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Task;
