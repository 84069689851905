function Budget({ allocatedBudget, currentSpend }) {
  return (
    <>
      <p className='inventory-item'>Allocated budget: ${allocatedBudget}</p>
      <p className='inventory-item'>Current spend: ${currentSpend}</p>
    </>
  );
}

export default Budget;
