import {RegisterAccount} from"features/register-account";
function Signup() {
	return (
		<>
		<RegisterAccount/>
		</>
	);
}

export default Signup;
