/*
Created On: August 16, 2023
Purpose: Performs Sorting Operation and skips sorting for already sorted data by using Memoization.
Dependencies:
	Components:

	Parameters: 
		1) items: List of items to sort.
    2) column: The name of the column being sorted on.
		3) sortConfig: 
            - Accepts json data with column and direction as keys.
            - Default value is null.
            - You have to store the returned sortConfig in the target file and pass the value again the following time you execute a sort. 
            Example: { column: 'start_date', direction: 'ascending' }

Return Values:
    - Returns json data as follows
        {
            sortedData: sorted data,
            config: updated sort config
        }
*/
const sortedItems = (items, sortConfig) => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.column] < b[sortConfig.column]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.column] > b[sortConfig.column]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return { sortedData: sortableItems, config: sortConfig };
}

const requestSort = (column, sortConfig) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.column === column && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    return { column, direction };
}


const getSortedData = (items_list, column, config= null) => {
    return sortedItems(items_list,  requestSort(column, config))
}

const cache = new Map();

const memoize = (fn) => {

  return (...args) => {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      return cache.get(key);
    }

    const result = fn(...args);
    cache.set(key, result);
    return result;
  };
}

const getMemoizedSortedData = memoize(getSortedData);


export default getMemoizedSortedData;