import { useState } from "react";
import { Link } from "react-router-dom";

function Task({
  tasks,
  completedSection,
  updateTask,
  numIncompleteTask,
  breadcrumb,
}) {
  let incompleteTasks;
  let completedTasks;

  completedTasks = tasks.filter(
    (task) => task.stage.toUpperCase() === "COMPLETED"
  );

  const [checked, setChecked] = useState(completedTasks.map((task) => task.id));

  incompleteTasks = tasks.filter(
    (task) => task.stage.toUpperCase() !== "COMPLETED"
  );

  incompleteTasks.length = numIncompleteTask;

  if (tasks.length === 0) {
    return (
      <div className='mx-2 small text-muted'>
        <p>No tasks has been assingned to this project.</p>
      </div>
    );
  }

  const handleChange = async (task) => {
    const updatedTask = {
      ...task,
      stage: "Completed",
    };
    await updateTask({
      taskId: task.id,
      body: updatedTask,
    });
  };

  const handleChangeForIncomplete = async (task) => {
    const updatedTask = {
      ...task,
      stage: "Incomplete",
    };
    await updateTask({
      taskId: task.id,
      body: updatedTask,
    });
  };

  const updateTaskStatus = (id) => {
    const updatedTask = tasks.find((task) => task.id === id);
    updatedTask.stage.toUpperCase() === "INCOMPLETE"
      ? handleChange(updatedTask)
      : handleChangeForIncomplete(updatedTask);
  };

  const handleCheckChange = (taskid) => {
    if (checked.includes(taskid)) {
      setChecked(checked.filter((id) => id !== taskid));
    } else {
      setChecked([...checked, taskid]);
    }
    updateTaskStatus(taskid);
  };

  return (
    <>
      <div className='mb-4'>
        <div className='list-group list-group-flush'>
          <h6 className='IncompletedTask'>Incomplete</h6>
          <div className='list-group list-group-flush'>
            {incompleteTasks.map((incompleteTask) => (
              <div className='list-group-item d-flex align-items-start pb-2'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start list-checkrow'>
                    <div className='flex-shrink-0'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id={incompleteTask.id}
                        checked={false}
                        onChange={() => handleCheckChange(incompleteTask.id)}
                      />
                      <label
                        className='form-check-label'
                        htmlFor={incompleteTask.id}
                      >
                        {/* <p className='flex-grow-1 list-task mx-2 small'>
														{incompleteTask.title}
													</p> */}
                        <Link
                          className='flex-grow-1 list-task mx-2 small'
                          to={`/tasks/${incompleteTask.id}`}
                          state={{
                            taskId: `${incompleteTask.id}`,
                            breadcrumb: breadcrumb,
                          }}
                        >
                          {incompleteTask.title}
                        </Link>
                        <p className='flex-grow-1 list-task mx-2 small text-muted'>
                          Date: {incompleteTask.end_date}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {completedSection && (
        <div className='mb-4'>
          <h2 className='completedTask'>Completed</h2>
          <div className='list-group list-group-flush'>
            {completedTasks.map((completedTask) => (
              <div className='list-group-item d-flex align-items-start pb-2'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start list-checkrow'>
                    <div className='flex-shrink-0'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id={completedTask.id}
                        checked={true}
                        onChange={() => handleCheckChange(completedTask.id)}
                      />
                      <label
                        className='form-check-label'
                        htmlFor={completedTask.id}
                      >
                        <p className='flex-grow-1 list-task mx-2 small'>
                          {completedTask.title}
                        </p>
                        <p className='flex-grow-1 list-task mx-2 small text-muted'>
                          Date: {completedTask.end_date}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
export default Task;
