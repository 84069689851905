import { useLocation } from "react-router-dom";
import { useGetProjectQuery } from "./projectsSlice";
import { Link } from "react-router-dom";

function ProjectBreadCrumb() {
  let projectInfo = [];
  const location = useLocation();

  const { projectId } = (location.state && location.state) || {};
  const { data, isSuccess, isError } = useGetProjectQuery(projectId);
  if (isSuccess) {
    projectInfo = data.data;
  }

  if (isError) {
    return <div>Error in loading data </div>;
  }
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb'>
        <li className='breadcrumb-item'>
          <Link
            to={`/clients/${projectInfo.vendor_account}`}
            state={{ clientId: `${projectInfo.vendor_account}` }}
          >
            Client Detail
          </Link>
        </li>
        <li className='breadcrumb-item'>
          <Link
            to={`/goals/${projectInfo.goal}`}
            state={{ goalId: `${projectInfo.goal}` }}
          >
            Goal Detail
          </Link>
        </li>
        <li className='breadcrumb-item'>
          <Link
            to={`/initiatives/${projectInfo.initiative}`}
            state={{ initiativeId: `${projectInfo.initiative}` }}
          >
            Initiative Detail
          </Link>
        </li>
        <li className='breadcrumb-item active' aria-current='page'>
          Project Detail
        </li>
      </ol>
    </nav>
  );
}

export default ProjectBreadCrumb;
