import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useIsTokenValidQuery } from "features/auth/authApiSlice";
import { logOut, authData } from "features/auth/authSlice";
import { skipToken } from "@reduxjs/toolkit/query";

const RouteHandler = () => {
  const dispatch = useDispatch();
  const auth = useSelector(authData);
  const local_auth = JSON.parse(localStorage.getItem("auth") || "{}");

  const { data: isValidToken, isError } = useIsTokenValidQuery(
    local_auth?.token ?? skipToken,
    {
      skip: !local_auth?.token,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (
      !local_auth?.token ||
      !auth?.token ||
      local_auth?.token !== auth?.token ||
      isError
    ) {
      dispatch(logOut());
    }
  }, [local_auth, auth, isError, dispatch]);

  const isValidLogin = () => {
    return (
      auth?.token &&
      isValidToken &&
      !isError &&
      local_auth?.token === auth?.token
    );
  };

  return isValidLogin() ? <Outlet /> : <Navigate to='/login' />;
};

export default RouteHandler;
