import { useState, useEffect } from "react";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Agenda } from "@microsoft/mgt-react";

//helps in tracking user's sign in
function useIsSignedIn() {
	const [isSignedIn, setIsSignedIn] = useState(false);

	useEffect(() => {
		const updateState = () => {
			const provider = Providers.globalProvider;
			setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
		};

		Providers.onProviderUpdated(updateState);
		updateState();

		return () => {
			Providers.removeProviderUpdatedListener(updateState);
		};
	}, []);

	return [isSignedIn];
}

function ScheduleList() {
	const [isSignedIn] = useIsSignedIn();

	return (
		<>
			{isSignedIn && <Agenda showMax='3' />}
			{isSignedIn === false ? (
				<div>
					Please click on 3 dots above and click on Sign in to access your
					microsoft calendar
				</div>
			) : null}
		</>
	);
}

export default ScheduleList;
