import {
  useGetInitiativeQuery,
  useAddInitiativeMutation,
  useUpdateInitiativeMutation,
} from "features/initiatives/initiativesSlice";
import FormHandler from "common/formHandler/FormHandler";
import { skipToken } from "@reduxjs/toolkit/query";

function InitiativeForm({ formInputs }) {
  let initiativeData;

  const getInitiativeData = useGetInitiativeQuery(
    (formInputs && formInputs.itemDetails && formInputs.itemDetails.id) ||
      skipToken
  );

  const [addInitiative] = useAddInitiativeMutation({
    refetchOnMountOrArgChange: true,
  });
  const [updateInitiative] = useUpdateInitiativeMutation({
    refetchOnMountOrArgChange: true,
  });

  if (getInitiativeData.isSuccess === true) {
    initiativeData = getInitiativeData.data.data;
  }

  return (
    <FormHandler
      formInputs={formInputs}
      addInitiative={addInitiative}
      updateInitiative={updateInitiative}
      initiativeData={initiativeData}
    />
  );
}

export default InitiativeForm;
