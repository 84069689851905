import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);
function Chart({ labels, data1, data2, title }) {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: title,
				font: {
					size: 16,
				},
			},
		},
	};

	const data = {
		labels: labels,
		datasets: [
			{
				label: "Current Value",
				data: data1,
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
			{
				label: "Target Value",
				data: data2,
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};
	return (
		<>
			<div className='col'>
				<Bar options={options} data={data} />
			</div>
		</>
	);
}

export default Chart;
