const urlRegex =
  /^(https?:\/\/)?(([A-Za-z\d-]+\.)+[A-Za-z]{2,}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[[A-Fa-f\d:]+\])(:\d+)?(\/\S*)?$/i;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const phoneRegex = /^\+?1?\d{8,15}$/;
const zipcodeRegex = /^\d{5}$/;

export function formValidation(
  formValues,
  goalFormValues,
  impactFormValues,
  clientFormValues,
  type
) {
  const commonErrorCheck = (formValues) => {
    let newErrors = {};

    if (type !== "Client") {
      if (formValues.form_title === "" || formValues.form_title === null) {
        newErrors.form_title = "Title is required";
      }
      if (formValues && formValues.form_title) {
        if (formValues.form_title.length > 200) {
          newErrors.form_title = "Title should not be more than 200 characters";
        }
      }
      if (formValues && formValues.form_description) {
        if (formValues.form_description.length > 200) {
          newErrors.form_description =
            "Description should not be more than 200 characters";
        }
      }
      if (formValues.form_stage === "" || formValues.form_stage === null) {
        newErrors.form_stage = "Stage should not be empty!";
      }
      if (formValues.form_status === "" || formValues.form_status === null) {
        newErrors.form_status = "Status should not be empty!";
      }
      if (
        formValues.form_owner_consultant === "" ||
        formValues.form_owner_consultant === null
      ) {
        newErrors.form_owner_consultant =
          "Owner consultant should not be empty!";
      }
      if (
        Date.parse(formValues.form_start_date) >
        Date.parse(formValues.form_end_date)
      ) {
        newErrors.form_end_date = "End Date Should be greater than Start date!";
      }
      if (
        formValues.form_client_account === "" ||
        formValues.form_client_account === null
      ) {
        newErrors.form_client_account = "Client Account should not be empty!";
      }
      if (
        (type !== "Goal" && formValues.form_goal === "") ||
        formValues.form_goal === null
      ) {
        newErrors.form_goal = "Goal should not be empty!";
      }
      return newErrors;
    } else {
      return null;
    }
  };
  const goalErrorCheck = (goalFormValues, type) => {
    let newErrors = {};
    if (type === "Goal") {
      if (goalFormValues.form_allocated_budget >= 9999999999) {
        newErrors.form_allocated_budget = "Allocated budget is too big";
      }
      if (goalFormValues.form_spend_budget >= 9999999999) {
        newErrors.form_spend_budget = "Spend budget is too big";
      }
    }
    if (type === "Initiative") {
      if (goalFormValues.form_spend_budget >= 999999) {
        newErrors.form_spend_budget = "Spend budget is too Big";
      }
    }

    return newErrors;
  };
  const impactErrorCheck = (impactFormValues) => {
    let newErrors = {};
    if (impactFormValues && impactFormValues.form_target_description) {
      if (impactFormValues.form_target_description.length > 2) {
        newErrors.form_target_description = "Target Description is too long";
      }
    }
    if (impactFormValues.form_current_value > 999999999999) {
      newErrors.form_current_value = "Current Value is too big";
    }
    if (impactFormValues.form_target_value > 999999999999) {
      newErrors.form_target_value = "Target Value is too big";
    }
    return newErrors;
  };
  const clientErrorCheck = (clientFormValues) => {
    let newErrors = {};
    if (type === "Client") {
      if (
        clientFormValues.form_name === "" ||
        clientFormValues.form_name === null
      ) {
        newErrors.form_name = "Client name is required";
      }
      if (
        clientFormValues.form_primary_consultant === "" ||
        clientFormValues.form_primary_consultant === null
      ) {
        newErrors.form_primary_consultant = "Primary consultant is required";
      }
      if (clientFormValues && clientFormValues.form_name) {
        if (clientFormValues.form_name.length > 200) {
          newErrors.form_name = "Title is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_website) {
        if (clientFormValues.form_website.length > 200) {
          newErrors.form_website = "Website is too long";
        }
      }

      if (
        clientFormValues.form_website !== null &&
        !urlRegex.test(clientFormValues.form_website)
      ) {
        newErrors.form_website = "Website is in incorrect format";
      }

      if (
        clientFormValues.form_email !== null &&
        !emailRegex.test(clientFormValues.form_email)
      ) {
        newErrors.form_email = "Email is in incorrect format";
      }
      if (clientFormValues && clientFormValues.form_phoneNumber) {
        if (
          !phoneRegex.test(clientFormValues.form_phoneNumber) ||
          clientFormValues.form_phoneNumber.length > 16
        ) {
          newErrors.form_phoneNumber = "Phone number is in incorrect format";
        }
      }
      if (clientFormValues && clientFormValues.form_city) {
        if (clientFormValues.form_city.length > 200) {
          newErrors.form_city = "City name is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_state) {
        if (clientFormValues.form_state.length > 200) {
          newErrors.form_state = "State name is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_country) {
        if (
          clientFormValues.form_country !== null &&
          clientFormValues.form_country.length > 200
        ) {
          newErrors.form_country = "Country name is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_zipcode) {
        if (
          !zipcodeRegex.test(clientFormValues.form_zipcode) ||
          clientFormValues.form_zipcode.length > 5
        ) {
          newErrors.form_zipcode = "Zip code is in incorrect format";
        }
      }

      return newErrors;
    } else {
      return null;
    }
  };

  const commonErrors = commonErrorCheck(formValues);
  const goalErrors = goalErrorCheck(goalFormValues, type);
  const impactErrors = impactErrorCheck(impactFormValues);
  const clientErrors = clientErrorCheck(clientFormValues);

  return { ...commonErrors, ...goalErrors, ...impactErrors, ...clientErrors };
}
