import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { useGetImpactHistoryQuery } from "features/focus-score/impactHistorySlice";
import { store } from "app/store";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);
// CV /TV
// Example with a Target Value (TV) of 100
//	   Score of 0: Range of == 0%
//     Score of 1: Range of >0% and <= 20% of target value
//         Example: AV = 13  (13 / 100 = .13 or 13% of target value)
//     Score of 2: Range of >20% and <= 40% of target value
//         Example: AV = 37  (37 / 100 = .37 or 37% of target value)
//     Score of 3: Range of >40% and <=60% of target value
//         Example: AV = 52  (52 / 100 = .52 or 52% of target value)
//     Score of 4: Range of >60% and <= 80% of target value
//         Example: AV = 65  (65 / 100 = .65 or 65% of target value)
//     Score of 5: Range of >80% and <= 100% of target value
//         Example: AV = 92  (92 / 100 = .92 or 92% of target value)

const FocusScore = ({ userid, impactid }) => {
  let userId = userid;

  if (!userId) {
    userId = store.getState().auth.userData.id;
  }

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Chart Model
  let scoreData = {
    labels: [], // will be populated later
    datasets: [
      {
        data: [], // will be populated later
        borderColor: "#1f2b5c",
        backgroundColor: "#9ac8ea",
        fill: true,
      },
    ],
    borderWidth: 1,
  };

  //Giving the userId as 0 if user is not logged in.It is temporary.
  //   if (store.getState().auth.userData === null) userId = 0;
  //   else userId = store.getState().auth.userData.id;

  const {
    data: impactHistoryData,
    isError,
    isSuccess,
  } = useGetImpactHistoryQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  const getScore = (difference) => {
    let score = 0;
    if (difference > 0 && difference <= 0.2) {
      score = 1;
    } else if (difference > 0.2 && difference <= 0.4) {
      score = 2;
    } else if (difference > 0.4 && difference <= 0.6) {
      score = 3;
    } else if (difference > 0.6 && difference <= 0.8) {
      score = 4;
    } else if (difference > 0.8 && difference <= 1) {
      score = 5;
    }

    return score;
  };

  // Calculate average of array data helper function
  const average = (array) => {
    let sum = 0;
    array.map((value) => (sum += value));

    return sum / array.length;
  };

  //initial data points to compensate for missing months data
  const initDataPoints = () => {
    let data = [];
    const now = new Date();
    now.setDate(1);
    now.setHours(0, 0, 0, 0);

    let currentMonth = now.getMonth() + 1;

    for (let i = 1; i <= 3; i++) {
      now.setMonth(currentMonth - i);

      const epoch = now.getTime();
      data.push({
        id: epoch,
        currentValues: [],
        targetValues: [],
        score: 0,
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      });
    }

    return data;
  };

  // Parse and calculate Focus Score and generate chart data format
  const calculateScoreDataPoints = (data) => {
    let points = initDataPoints();

    data.forEach((item) => {
      // TODO Fix backend to include timestamp
      const date = new Date(item.modified_on_date + " 00:00");

      // because of the format of the date + 1 needs to be added to the month
      // see: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off#:~:text=Javascript%27s%20Date%20class%20doesn%27t,%3A00%20GMT%2D0400).
      let firstOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

      // used to group data points by there month and year
      const epoch = firstOfTheMonth.getTime();

      points.forEach(function (point) {
        if (point.id === epoch) {
          point.currentValues.push(parseFloat(item.current_value));
          point.targetValues.push(parseFloat(item.target_value));
        }
      });
    });

    // Sort data in accending order
    points.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

    // only show 3 months of data
    while (points.length > 3) {
      points.shift();
    }

    // Format data for react-chartjs-2
    points.forEach(function (point) {
      let currentAverage;
      let targetAverage;

      currentAverage = average(point.currentValues);
      targetAverage = average(point.targetValues);

      scoreData.labels.push(labels[point.month - 1]);
      scoreData.datasets[0].data.push(getScore(currentAverage / targetAverage));
    });
  };

  if (isSuccess) {
    let filteredData = [];
    if (impactid) {
      impactHistoryData.data.forEach((data) => {
        if (data.impact_id === Number(impactid)) {
          filteredData.push(data);
        }
      });
    } else {
      filteredData = impactHistoryData.data;
    }

    calculateScoreDataPoints(filteredData);
  }

  if (isError) {
    calculateScoreDataPoints([]);
  }

  return (
    <>
      <div className='card equal-height'>
        <div className='card-body'>
          <div className='row'>
            <Line
              options={{
                scales: {
                  y: {
                    suggestedMin: 1,
                    suggestedMax: 5,
                  },
                },
              }}
              data={scoreData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FocusScore;
